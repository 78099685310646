import { debounce } from 'lodash';
import { z } from 'zod';

import { useInteractionsState } from 'stores/interactionsStore.ts';
import { TDynamicContext } from 'types/dynamicExpressions.ts';
import { TSyncValue } from 'types/index';
import { arraysAreEqual } from 'utils/array.ts';
import { evaluateCondition } from 'utils/dynamicExpressions.ts';

function _updateMetaDossierErrors() {
  console.time('updating errors');
  const metaDossierWipElByMetaPath =
    useInteractionsState.getState().metaDossierWipElByMetaPath!;
  const metaDossierData =
    useInteractionsState.getState().metaDossierInfo!.metaDossierData!;
  const dossier = useInteractionsState.getState().dossier!;

  const baseContext: Record<string, TSyncValue> = {};
  const opContextByOpId: Record<string, Record<string, TSyncValue>> = {};

  // First we build the context that will be used to evaluate the dynamic rules
  for (const [metaPath, info] of metaDossierWipElByMetaPath.entries()) {
    if (
      metaPath.startsWith('chantier.') ||
      metaPath.startsWith('benef.') ||
      metaPath.startsWith('company.')
    ) {
      // metaPath will be like 'chantier.<field>' or 'benef.<field>' or 'company.<field>'
      baseContext[metaPath.replace('.', '|')] = info.value;
    } else {
      // metaPath will be like 'operations.<rowIdEncoded>.data.<field>
      const rowIdEncoded = metaPath.split('.')[1];
      const codeAdeme = metaDossierData.operations[rowIdEncoded].codeAdeme;
      const field = metaPath.split('.')[3];

      if (!(rowIdEncoded in opContextByOpId)) {
        opContextByOpId[rowIdEncoded] = {};
      }
      opContextByOpId[rowIdEncoded][`op|${codeAdeme}|${field}`] = info.value;
    }
  }

  const next = new Map(metaDossierWipElByMetaPath);

  // Then we evaluate the rules
  for (const [metaPath, info] of metaDossierWipElByMetaPath.entries()) {
    const errors = [];

    // Errors on other types are not possible at the moment
    // (things are checked input themselves)
    if (info.type === 'email-address') {
      if (
        info.value !== null &&
        !z.string().email().safeParse(info.value).success
      ) {
        errors.push('Email invalide.');
      }
    }

    const context: TDynamicContext = {
      codesAdeme: dossier?.codesAdeme ?? [],
      metaContext: metaPath.startsWith('operations.')
        ? { ...baseContext, ...opContextByOpId[metaPath.split('.')[1]] }
        : { ...baseContext },
    };

    for (const rule of info.dynamicRules) {
      if (typeof rule === 'string') {
        errors.push(rule);
      } else {
        if (rule.precondition) {
          if (!evaluateCondition(rule.precondition, context)) {
            continue;
          }
        }
        if (!evaluateCondition(rule.check, context)) {
          if ('label' in rule) {
            errors.push(rule.label);
          } else {
            errors.push(...rule.labels);
          }
        }
      }
    }
    errors.sort((a, b) => a.localeCompare(b));
    // We update only if there is a change to reduce react re-renders
    if (!arraysAreEqual(errors, info.errors)) {
      // We update the errors
      next.set(metaPath, { ...next.get(metaPath)!, errors });
    }
  }

  useInteractionsState.setState({ metaDossierWipElByMetaPath: next });
  console.timeEnd('updating errors');
}

export const updateMetaDossierErrors = debounce(_updateMetaDossierErrors, 300);
