import { AnnotStatus, IPreparedEntities, TReviewInfo } from 'types/index';

export const getReviewStatus = (remote: {
  reviewed: boolean | null;
  isValid: boolean | null;
}): 'pending' | 'valid' | 'invalid' => {
  if (!remote.reviewed) {
    return 'pending';
  }
  if (remote.isValid === true) {
    return 'valid';
  } else if (remote.isValid === false) {
    return 'invalid';
  }
  console.warn("Weird case, shouldn't happen");
  return 'pending';
};

export const getReviewInfo = (
  preparedEntities: IPreparedEntities,
  entitiesStatuses: Map<string, AnnotStatus>,
  entitiesRejectionReasons: Map<string, string>
): TReviewInfo => {
  const emptyStats = {
    [AnnotStatus.VALIDATED]: 0,
    [AnnotStatus.REJECTED]: 0,
    [AnnotStatus.PENDING]: 0,
  };
  if (preparedEntities?.length === 0) {
    return {
      stats: emptyStats,
      rejectedEntities: [],
    };
  }

  const allEntities = (
    preparedEntities[0]?.entitiesForDoc[0]?.preparedSection ?? []
  )
    .flatMap((el) => [
      ...el.missingEntities,
      ...el.groups.flatMap((el) => el.entities),
    ])
    .map((el) => ({
      id: el.id,
      label: el.label,
      status: entitiesStatuses.get(el.id) || AnnotStatus.PENDING,
      rejectionReason: entitiesRejectionReasons?.get(el.id) || '',
      kind: el.kind,
    }));

  const stats = allEntities.reduce((acc, el) => {
    acc[el.status]++;
    return acc;
  }, emptyStats);

  return {
    stats,
    rejectedEntities: allEntities.filter(
      (el) => el.status === AnnotStatus.REJECTED
    ),
  };
};
