import dayjs from 'dayjs';

import {
  TDynamicCondition,
  TDynamicContext,
  TDynamicValue,
  TDynamicArrValue,
  TCaseValue,
} from 'types/dynamicExpressions.ts';
import { TDynamicRules } from 'types/index';

export function evaluateCase(
  value: TCaseValue,
  context: TDynamicContext
): string | number | boolean | null {
  for (let i = 1; i < value.length - 1; i += 1) {
    // @ts-ignore
    if (evaluateCondition(value[i][0] as TDynamicCondition, context)) {
      // @ts-ignore
      return evaluateValue(value[i][1] as TDynamicValue, context);
    }
  }
  console.log('No match');
  return evaluateValue(value[value.length - 1] as TDynamicValue, context);
}

export function evaluateValue(
  value: TDynamicValue,
  context: TDynamicContext
): string | number | boolean | null {
  if (Array.isArray(value) && value.length > 0) {
    if (value.length === 1 && value[0] === 'docType') {
      if (!context.docType) {
        throw new Error('docType is not available in the context');
      }
      return context.docType;
    } else if (value.length === 3 && value[0] === 'deltaDays') {
      const date1 = evaluateValue(value[1] as TDynamicValue, context) as string;
      const date2 = evaluateValue(value[2] as TDynamicValue, context) as string;
      return dayjs(date1).diff(dayjs(date2), 'day') as number;
    } else if (value.length === 2 && value[0] == 'metaField') {
      if (context.metaContext && value[1] in context.metaContext) {
        return context.metaContext[value[1] as string];
      } else {
        return null;
      }
    } else if (value[0] === 'case') {
      return evaluateCase(value as TCaseValue, context);
    } else {
      return evaluateBooleanExpression(value as TDynamicCondition, context);
    }
  } else {
    // @ts-ignore
    return value;
  }
}

export function evaluateArrayValue(
  value: TDynamicArrValue | TDynamicValue[],
  context: TDynamicContext
): (string | number | boolean | null)[] {
  if (Array.isArray(value)) {
    if (value.length === 1 && value[0] === 'codesAdeme') {
      return context.codesAdeme;
    } else {
      return value.map((v) => evaluateValue(v, context));
    }
  } else {
    return value;
  }
  throw new Error(`Unknown dynamic array value: ${value}`);
}

export function evaluateBooleanExpression(
  condition: TDynamicCondition,
  context: TDynamicContext
): boolean {
  if (condition === true) return true;
  if (condition[0] === 'nullOrEmpty') {
    const value = evaluateValue(condition[1] as TDynamicValue, context);
    return value === '' || value === null || value === undefined;
  } else if (condition[0] === 'notNullOrEmpty') {
    const value = evaluateValue(condition[1] as TDynamicValue, context);
    return value !== '' && value !== null && value !== undefined;
  } else if (condition[0] === 'regexValid') {
    const value = evaluateValue(
      condition[1] as TDynamicValue,
      context
    ) as string;
    const regex = new RegExp(evaluateValue(condition[2], context) as string);
    return regex.test(value);
  } else if (condition[0] === 'case') {
    return evaluateCase(condition, context) as boolean;
  } else if (condition[0] === 'codesAdemeIn') {
    return evaluateBooleanExpression(
      ['intersects', ['codesAdeme'], condition[1]],
      context
    );
  } else if (condition[0] === 'and') {
    return condition
      .slice(1)
      .every((c) => evaluateCondition(c as TDynamicCondition, context));
  } else if (condition[0] === 'or') {
    return condition
      .slice(1)
      .some((c) => evaluateCondition(c as TDynamicCondition, context));
  } else if (condition[0] === '!') {
    return !evaluateCondition(condition[1] as TDynamicCondition, context);
  } else if (condition.length === 3) {
    const [operator, left, right] = condition;
    switch (operator) {
      case '==':
        return evaluateValue(left, context) === evaluateValue(right, context);
      case '!=':
        return evaluateValue(left, context) !== evaluateValue(right, context);
      case '>':
        return (
          (evaluateValue(left, context) ?? -Infinity) >
          (evaluateValue(right, context) ?? +Infinity)
        );
      case '<':
        return (
          (evaluateValue(left, context) ?? +Infinity) <
          (evaluateValue(right, context) ?? -Infinity)
        );
      case '>=':
        return (
          (evaluateValue(left, context) ?? -Infinity) >=
          (evaluateValue(right, context) ?? +Infinity)
        );
      case '<=':
        return (
          (evaluateValue(left, context) ?? +Infinity) <=
          (evaluateValue(right, context) ?? -Infinity)
        );
      case 'in':
        return evaluateArrayValue(right, context).includes(
          evaluateValue(left, context)
        );
      case 'intersects':
        // eslint-disable-next-line no-case-declarations
        const rightSet = new Set(evaluateArrayValue(right, context));
        return evaluateArrayValue(left, context).some((l) => rightSet.has(l));
    }
  }
  return false;
}

export function evaluateCondition(
  condition: TDynamicCondition,
  context: TDynamicContext
): boolean {
  return evaluateBooleanExpression(condition, context);
}

export function getRules(
  dynamicRules: TDynamicRules[],
  context: TDynamicContext
): string[] {
  if (!Array.isArray(dynamicRules) || dynamicRules.length === 0) return [];

  const out: string[] = [];
  dynamicRules.forEach((rule) => {
    if (typeof rule === 'string') {
      out.push(rule);
    } else if (
      !rule.precondition ||
      evaluateCondition(rule.precondition, context)
    ) {
      if ('labels' in rule) {
        out.push(...rule.labels);
      } else if ('label' in rule) {
        out.push(rule.label);
      }
    }
  });
  return out;
}
