import HistoryIcon from '@mui/icons-material/History';
import { Tooltip } from '@mui/joy';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import React, { useMemo } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { infoByAnnotStatus } from 'components/Entity/annotStatusConstants.tsx';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import {
  IEntityForDisplay,
  IMissingEntity,
  IPostProcessedEntity,
} from 'types/index';

function EntityHistoryF({
  entity,
}: {
  entity: IEntityForDisplay | IPostProcessedEntity | IMissingEntity;
}) {
  const { t } = useTranslation();
  const hasPrev = useInteractionsState(
    useShallow((state) => state.prevVersionData.size > 0)
  );
  const prevInfo = useInteractionsState(
    useShallow((state) => state.prevVersionData.get(entity.comparisonId))
  );
  const tooltipContent = useMemo(() => {
    if (!prevInfo) return t('history.info.newEntity');
    let out = `${t('history.info.oldStatus')} ${t(`history.status.${prevInfo.status}`)}`;
    if (prevInfo.rejectedReason) {
      out += `\n${t('history.info.rejectionReason')} ${prevInfo.rejectedReason}`;
    }
    return out;
  }, [prevInfo, t]);

  const oldText =
    prevInfo?.kind === 'missing'
      ? ''
      : prevInfo?.transformedText.replace(/\n/g, ' ').trim();
  const newText =
    entity?.kind === 'missing'
      ? ''
      : entity.transformedText.replace(/\n/g, ' ').trim();

  return (
    <Tooltip
      title={
        !hasPrev ? (
          ''
        ) : (
          <Box padding={1}>
            <Typography whiteSpace={'pre'}>{tooltipContent}</Typography>
            {prevInfo?.kind === 'missing' && (
              <Typography level={'body-xs'}>
                {t('history.info.previousEntityMissing')}
              </Typography>
            )}
            {oldText === newText ? (
              prevInfo?.kind === 'missing' && entity.kind === 'missing' ? (
                <></>
              ) : (
                <Typography level={'body-xs'}>
                  {t('history.info.textUnchanged')}
                </Typography>
              )
            ) : (
              <Box maxWidth={600}>
                <ReactDiffViewer
                  oldValue={oldText}
                  newValue={
                    entity.kind === 'missing' ? '' : entity.transformedText
                  }
                  compareMethod={DiffMethod.CHARS}
                  hideLineNumbers={true}
                  splitView={false}
                  useDarkTheme={true}
                />
              </Box>
            )}
          </Box>
        )
      }
    >
      <span>
        <IconButton
          disabled={!prevInfo}
          size={'sm'}
          color={
            prevInfo?.status
              ? infoByAnnotStatus[prevInfo?.status].muiColor
              : 'neutral'
          }
        >
          <HistoryIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

const EntityHistory = React.memo(EntityHistoryF);
export default EntityHistory;
