import '@fontsource/inter';
import Box from '@mui/joy/Box';

import Header from 'components/UI/Header';
import SideBar from 'components/UI/SideBar';

function SideBarLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <SideBar />

      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: 'calc(12px + var(--Header-height))',
            sm: 'calc(12px + var(--Header-height))',
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100vh',
          gap: 1,
        }}
      >
        <div id="detail">{children}</div>
      </Box>
    </>
  );
}

export default SideBarLayout;
