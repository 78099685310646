import FiberNewIcon from '@mui/icons-material/FiberNew';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MarkdownEditor } from 'components/Editor/MarkdownEditor.tsx';
import { useChantierState } from 'stores/chantierStore.ts';
import { useInteractionsState } from 'stores/interactionsStore';

export function InternalReviewNote() {
  const { t } = useTranslation();
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const note = useChantierState(
    (state) => state.chantierInternalReviewNotesMarkdown
  );
  const remoteNote = useChantierState(
    (state) => state.remoteChantierInternalReviewNotesMarkdown
  );
  const setNote = useChantierState(
    (state) => state.setChantierInternalReviewNotesMarkdown
  );

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      sx={{ flexGrow: 1, flexBasis: 0 }}
      expanded={expanded}
      onChange={(_, newExpanded) => setExpanded(newExpanded)}
    >
      <AccordionSummary>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {
            <Typography
              level={'title-md'}
              sx={{ fontWeight: 'var(--joy-fontWeight-lg) !important' }}
            >
              {t('review.internalChantierNote.title')}
            </Typography>
          }
          {note !== remoteNote && <FiberNewIcon />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          maxHeight: '40vh',
          maxWidth: '100%',
          ...(expanded ? { mt: 1 } : { mb: 0, pb: 0 }),
        }}
      >
        <MarkdownEditor
          value={note}
          onChange={setNote}
          readOnly={isInReadOnlyMode}
          placeholder={''}
        ></MarkdownEditor>
      </AccordionDetails>
    </Accordion>
  );
}
