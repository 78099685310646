import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { isEqual } from 'lodash';

import i18n from 'i18n.ts';
import { IDocType, IDocTypesByFileByPage } from 'types/index';

type TBookmarkTreeEntry = {
  title: string;
  pageNumber: number;
  children: TBookmarkTreeEntry[];
};
const bookmarksToTree = (bookmarks: Core.Bookmark[]): TBookmarkTreeEntry[] => {
  const tree: TBookmarkTreeEntry[] = [];
  for (const bookmark of bookmarks) {
    bookmark.isValid();
    const children = bookmark.getChildren();
    tree.push({
      title: bookmark.getName(),
      pageNumber: bookmark.getPageNumber(),
      children: children.length > 0 ? bookmarksToTree(children) : [],
    });
  }
  tree.sort((a, b) => a.pageNumber - b.pageNumber);
  return tree;
};

const getTargetBookmarksTree = (
  docTypesByFileByPage: IDocTypesByFileByPage,
  docExtraLabelByDocKey: Record<string, string>
) => {
  return Object.keys(docTypesByFileByPage)
    .map((docType): TBookmarkTreeEntry => {
      const translatedDocType = i18n.t(`docTypes.${docType}.name`);
      const files = docTypesByFileByPage[docType as IDocType]!;
      const firstPageNumber = Math.min(...Object.values(files).flat(2));
      return {
        title: translatedDocType,
        pageNumber: firstPageNumber,
        children: Object.entries(files)
          .map(([fileName, pages]) => {
            const firstPageOfFile = Math.min(...pages);
            const childOutlineName = `${translatedDocType} ${docExtraLabelByDocKey[fileName]}`;
            return {
              title: childOutlineName,
              pageNumber: firstPageOfFile,
              children: [],
            };
          })
          .sort((a, b) => a.pageNumber - b.pageNumber),
      };
    })
    .sort((a, b) => a.pageNumber - b.pageNumber);
};

const createBookmarkTree = async (
  bookmarkTree: TBookmarkTreeEntry[],
  pdfDoc: Core.PDFNet.PDFDoc,
  PDFNet: typeof Core.PDFNet
): Promise<Core.PDFNet.Bookmark[]> => {
  const added = [];
  for (const entry of bookmarkTree) {
    const bookmark = await PDFNet.Bookmark.create(pdfDoc, entry.title);
    const page = await pdfDoc.getPage(entry.pageNumber);
    await bookmark.setAction(
      await PDFNet.Action.createGoto(await PDFNet.Destination.createFit(page))
    );
    if (entry.children.length > 0) {
      const children = await createBookmarkTree(entry.children, pdfDoc, PDFNet);
      for (const child of children) {
        await bookmark.addChild(child);
      }
    }
    added.push(bookmark);
  }
  return added;
};

/**
 * Function to compare and add bookmarks to a PDF document based on the document types and files.
 * Creates hierarchical outlines with root bookmarks for document types and child bookmarks for files.
 *
 * @param {any} doc - The PDF document instance.
 * @param {any} PDFNet - The PDFNet instance.
 * @param {IDocTypesByFileByPage} docTypesByFileByPage - An object mapping document types to files and their pages.
 * @param {any} t - Translation function for localizing document type names.
 * @param remappedPageNumber - Page number mapping that is used for displaying
 * @returns {Promise<boolean[]>} - A promise that resolves to an array indicating which document types had bookmarks added.
 */
export const resetBookmarksAndCreateFromDocTypesByFileByPage = async ({
  doc,
  PDFNet,
  docTypesByFileByPage,
  docExtraLabelByDocKey,
}: {
  doc: Core.Document;
  PDFNet: typeof Core.PDFNet;
  docTypesByFileByPage: IDocTypesByFileByPage;
  docExtraLabelByDocKey: Record<string, string>;
}): Promise<boolean> => {
  const bookmarks = await doc.getBookmarks();
  const tree = bookmarksToTree(bookmarks);
  const targetTree = getTargetBookmarksTree(
    docTypesByFileByPage,
    docExtraLabelByDocKey
  );

  if (isEqual(tree, targetTree)) {
    console.log("Outline tree doesn't need to be updated, skipping");
    return false;
  }

  // Otherwise (a doc has been completly deleted for instance) we recreate the outline from scratch

  // Retrieve the PDF document and its bookmarks
  const pdfDoc = await doc.getPDFDoc();

  try {
    // Quick delete of all bookmarks
    await (await pdfDoc.getRoot()).eraseFromKey('Outlines');

    const rootBookmarks = await createBookmarkTree(targetTree, pdfDoc, PDFNet);
    for (const bookmark of rootBookmarks) {
      await pdfDoc.addRootBookmark(bookmark);
    }
  } catch (err) {
    console.error('error in createBookmarkTree', err);
  }
  return true;
};

export const buildOutline = async (
  instance: WebViewerInstance,
  docTypesByFileByPage: IDocTypesByFileByPage,
  docExtraLabelByDocKey: Record<string, string>
) => {
  const { Core, UI } = instance;
  const { documentViewer, PDFNet } = Core;
  const { reloadOutline } = UI;
  const doc = documentViewer.getDocument();

  // Compare the bookmarks with the doc types split By Page
  const bookMarksAdded = await resetBookmarksAndCreateFromDocTypesByFileByPage({
    doc,
    PDFNet,
    docTypesByFileByPage,
    docExtraLabelByDocKey,
  });

  if (bookMarksAdded) {
    // Reloads the Bookmark Outline in the WebViewer UI              }
    reloadOutline();
  }
};

export const updateOutlineSelected = async (
  doc: Core.Document,
  targetPageNumber: number
) => {
  // A bit of algorithms to go through the bookmarks tree and find the closest bookmark to the target page number
  // the idea is that in the html bookmarks order will be coherent when doing
  // .getElementsByClassName(
  //     'bookmark-outline-single-container'
  //   )
  let i = -1;
  let closestPageNumber: number | null = null;
  let closestBookmarkIdx: number | null = null;

  // This will be recursive but with an external state (yeah not very clean but it works)
  // FIXME: refactor this
  const findClosestBookmarkIdx = (children: Core.Bookmark[]) => {
    if (children.length === 0) {
      return;
    }
    for (const child of children) {
      i = i + 1;
      const childChildren = child.getChildren();
      if (childChildren.length === 0) {
        const childPageNumber = child.getPageNumber();
        if (childPageNumber <= targetPageNumber) {
          if (closestPageNumber === null) {
            closestPageNumber = childPageNumber;
            closestBookmarkIdx = i;
          } else {
            if (childPageNumber > closestPageNumber) {
              closestPageNumber = childPageNumber;
              closestBookmarkIdx = i;
            }
          }
        }
      } else {
        findClosestBookmarkIdx(childChildren);
      }
    }
  };
  findClosestBookmarkIdx(await doc.getBookmarks());

  // Finally we hijack apryse window to fix its content :nerd:
  [
    ...document
      .getElementsByTagName('iframe')
      .item(0)!
      .contentWindow!.document.getElementsByClassName(
        'bookmark-outline-single-container'
      ),
  ].forEach((elem, idx) => {
    if (idx === closestBookmarkIdx) {
      elem.classList.add('selected');
    } else {
      elem.classList.remove('selected');
    }
  });
};
