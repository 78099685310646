import { DialogActions, DialogContent, DialogTitle } from '@mui/joy';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useTranslation } from 'react-i18next';

import { HomeEmail } from 'components/Review/HomeEmail.tsx';
import HomeChantier from 'components/UI/HomeChantier.tsx';
import HomePage from 'components/UI/HomePage.tsx';
import Station from 'components/UI/Station.tsx';
import { useAppState } from 'stores/appStore.ts';

export default function SpaHomeStation() {
  const { t } = useTranslation();
  const resetNavigateNext = useAppState((state) => state.resetNavigateNext);
  const newVersionAvailable = useAppState((state) => state.newVersionAvailable);
  const hasChangesToSave = useAppState((state) => state.hasChangesToSave);
  const navigateNext = useAppState((state) => state.navigateNext);
  const navigate = useAppState((state) => state.navigate);
  const urlParams = useAppState((state) => state.urlParams);

  const mode = window.location.pathname.endsWith('email')
    ? 'email'
    : typeof urlParams?.chantierId === 'string'
      ? typeof urlParams?.dossierId === 'string'
        ? 'station'
        : 'chantierHome'
      : typeof urlParams?.dossierId === 'string'
        ? 'station'
        : 'mainHome';

  return (
    <>
      <Modal
        open={hasChangesToSave && !!navigateNext}
        onClose={resetNavigateNext}
        keepMounted={false}
      >
        <ModalDialog>
          <DialogTitle>{t('navigation.unsavedChangesModal.title')}</DialogTitle>
          <DialogContent>
            {t('navigation.unsavedChangesModal.description')}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" onClick={resetNavigateNext}>
              {t('navigation.unsavedChangesModal.no')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => navigate(navigateNext!, true)}
            >
              {t('navigation.unsavedChangesModal.yes')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Modal open={newVersionAvailable} onClose={() => {}} keepMounted={false}>
        <ModalDialog>
          <DialogTitle>{t('newAppVersion.title')}</DialogTitle>
          <DialogContent>{t('newAppVersion.description')}</DialogContent>
          <DialogActions>
            <Button variant="solid" onClick={() => window.location.reload()}>
              {t('newAppVersion.btn')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      {mode === 'mainHome' && <HomePage />}
      {mode === 'chantierHome' && <HomeChantier />}
      {mode === 'email' && <HomeEmail />}
      <div
        style={
          mode === 'station'
            ? { height: '100vh', width: '100vw' }
            : { width: 0, height: 0, overflow: 'hidden' }
        }
      >
        <Station invisible={mode !== 'station'} />
      </div>
    </>
  );
}
