import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';

import { CODE_ADEME } from 'utils/constants';

export type ICodeAdeme = {
  code: string;
  name: string;
  status?: string;
  statusLabel?: string;
};

const SelectCode = ({
  selectedCodes,
  setSelectedCodes,
}: {
  selectedCodes: ICodeAdeme[];
  setSelectedCodes: (value: ICodeAdeme[]) => void;
}) => {
  return (
    <Autocomplete
      multiple
      id="code-ademe-autocomplete"
      options={CODE_ADEME}
      getOptionLabel={(option) => option.code}
      renderOption={(props, option) => (
        <AutocompleteOption {...props}>
          <Typography level="body-xs">
            {option.code} - {option.name}{' '}
            {option?.statusLabel ? `- ${option?.statusLabel}` : ''}
          </Typography>
        </AutocompleteOption>
      )}
      value={selectedCodes}
      required={selectedCodes.length === 0}
      onChange={(_, newValue) => setSelectedCodes(newValue)}
      renderTags={(tags, getTagProps) =>
        tags.map((item, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            variant="outlined"
            color="primary"
            endDecorator={<Close />}
            sx={{ minWidth: 0 }}
            {...getTagProps({ index })}
          >
            {item.code}
          </Chip>
        ))
      }
    />
  );
};

export default SelectCode;
