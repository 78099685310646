import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import AttestationsFinder from 'components/AttestationRGE/AttestationsFinder.tsx';
import { getPDFNet } from 'utils/apryze.ts';

export default function HomeAttestations() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // We initialize the PDFNet library early for later use
    getPDFNet();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <h1>Attestations RGE 🔍</h1>
      <AttestationsFinder
        initialSiret={searchParams.get('siret')!}
        initialCodesAdeme={searchParams.getAll('code_ademe')}
      />
    </div>
  );
}
