import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { getEntitiesStatusFromAnnotations } from 'utils/annotations.ts';
import { updateOutlineSelected } from 'utils/bookmarks.ts';
import { ABRICO_ANNOTATION_PREFIX } from 'utils/constants.ts';
import { rescalePagesWidthToA4 } from 'utils/pdf.ts';

export const checkElementIsVisible = (
  elm: Element,
  offset: number = 0
): boolean => {
  const rect = elm.getBoundingClientRect();
  // @ts-ignore
  if (!elm.checkVisibility({ opacityProperty: true })) return false;

  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );

  return rect.bottom + offset < viewHeight && rect.top - offset > 0;
};

export const setFocusEntityFromDocumentViewer = (
  instance: WebViewerInstance
) => {
  const { annotationManager } = instance.Core;

  annotationManager.addEventListener(
    'annotationSelected',
    (annotations: Core.Annotations.Annotation[]) => {
      // We prevent the selection of the BB in the UI
      const abricoAnnots = annotations.filter((annot) =>
        annot.Id.startsWith(ABRICO_ANNOTATION_PREFIX)
      );
      if (abricoAnnots.length > 0) {
        annotationManager.deselectAnnotations(abricoAnnots);
      }
    }
  );

  annotationManager.addEventListener(
    'annotationDoubleClicked',
    (annotation: Core.Annotations.Annotation) => {
      if (annotation.Id.startsWith(ABRICO_ANNOTATION_PREFIX)) {
        useUiState.getState().setFocusedEntityId(annotation.Id.split('-')[0]);
      }
    }
  );
};

export const initInstance = (instance: WebViewerInstance) => {
  const { documentViewer, annotationManager } = instance.Core;

  let documentIsLoaded = false;
  let changesHistory: any[] = [];

  documentViewer.addEventListener('documentUnloaded', () => {
    documentIsLoaded = false;
    changesHistory = [];
  });

  documentViewer.addEventListener(
    'pagesUpdated',
    async (changes: Core.DocumentViewer.pagesUpdatedChanges) => {
      if (!documentIsLoaded) return;

      if (changes.linearizedUpdate) {
        return;
      }

      const dossier = await useInteractionsState.getState().getDossier();

      const prevEntitiesPageNumbersTracker =
        dossier.entitiesPageNumbersTrackerCopy;
      const hasChanges = await dossier.trackPdfChanges(changes, instance);
      if (hasChanges) {
        useAppState.getState().setHasEntitiesRelatedChangesToSave(true);
      }

      // We keep track of potential errors in changes handling, we still seem to have bugs around that
      const nbPages = instance.Core.documentViewer.getDocument().getPageCount();

      changesHistory.push({
        changes,
        prevEntitiesPageNumbersTracker,
        entitiesPageNumbersTracker: dossier.entitiesPageNumbersTrackerCopy,
        nbPages,
        dossierInitializedAt: dossier._initalizedAt,
      });

      // We fixed the issue of the page tracking after the 15th of October 2024
      // So we log only new errors
      const shouldBeGoodDate = new Date(2024, 9, 15);
      const shouldBeGoodSeconds = shouldBeGoodDate.getTime() / 1000;

      if (
        Object.values(
          dossier.updatedDisplayedPageNumberByWorkflowPageNumber
        ).some((v) => v !== null && v > nbPages) &&
        // @ts-ignore
        (dossier.createdAt?.seconds ?? 0) > shouldBeGoodSeconds
      ) {
        console.warn(
          `Page tracking issues context: ${JSON.stringify({
            changesHistory,
          })}`
        );
        console.error(
          `Page tracking failed at some point. Check what happened.`
        );
      }

      if (changes.added.length > 0) {
        const updatedDoc = documentViewer.getDocument();
        const doc = await updatedDoc.getPDFDoc();
        await instance.Core.PDFNet.runWithCleanup(
          async () => await rescalePagesWidthToA4(doc, changes.added),
          import.meta.env.VITE_APRYSE_KEY
        );
        // We refresh the viewer to avoid visual glitches and to apply the document changes
        // Note that the updatedDoc.rotatePages of 0 is a HACK. Sorry I haven't found
        // another WORKING way to properly refresh the viewer lol.
        await updatedDoc.rotatePages(changes.added, 0);
        // This helps to remove rendering glitches
        documentViewer.refreshAll();
        documentViewer.updateView();
      }
    }
  );

  documentViewer.addEventListener(
    'pageNumberUpdated',
    async (pageNumber: number) => {
      if (!documentIsLoaded) return;
      await updateOutlineSelected(documentViewer.getDocument(), pageNumber);
    }
  );

  documentViewer.addEventListener('documentLoaded', async () => {
    documentIsLoaded = true;
    useUiState.getState().setViewerIsReady(true);
    useAppState.getState().logDurationInFs({
      durationMs: performance.now() - useAppState.getState().refDossierStartMs,
      name: 'Ready for first interactions',
    });
    const dossier = await useInteractionsState.getState().getDossier();
    changesHistory = [
      {
        start: true,
        entitiesPageNumbersTracker: dossier.entitiesPageNumbersTrackerCopy,
        nbPages: instance.Core.documentViewer.getDocument().getPageCount(),
        dossierInitializedAt: dossier._initalizedAt,
      },
    ];

    setFocusEntityFromDocumentViewer(instance);
  });

  documentViewer.addEventListener('annotationsLoaded', async () => {
    annotationManager.getAnnotationsList().forEach((annotation) => {
      if (annotation instanceof instance.Core.Annotations.FreeTextAnnotation) {
        annotation.FillColor = new instance.Core.Annotations.Color(0, 0, 0, 0);
        alert(
          `Une annotation mentionnant le texte '${annotation.getContents()}' susceptible de cacher un autre texte était présente sur le PDF page ${annotation.getPageNumber()}. Nous avons retiré la couleur de fond de cette dernière.`
        );
      }
    });

    const documentFullyLoaded = useUiState.getState().documentFullyLoaded;
    if (!documentFullyLoaded) {
      useInteractionsState
        .getState()
        .getDossier()
        .then(async (notNullDossier) => {
          // FIXME remove this
          // once data has been migrated: https://www.notion.so/abrico/Rework-entities-ID-logic-strip-mentionText-of-ids-and-have-a-non-annonymized-version-will-need-mig-24095f06901740dca808e17edbe6ee86?pm=c
          useInteractionsState.getState().mergeData({
            entitiesStatuses: getEntitiesStatusFromAnnotations(instance),
          });
          await notNullDossier.refreshUI(
            await useAppState.getState().getInstance()
          );

          useUiState.getState().setDocumentIsFullyLoaded();
        });
    }
  });
};
