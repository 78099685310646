import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface PersistedStore {
  hideReviewedEntitiesSettings: boolean;
  setHideReviewedEntitiesSettings: (hide: boolean) => void;
}

export const usePersistedStore = create<PersistedStore>()(
  persist(
    (set) => ({
      hideReviewedEntitiesSettings: false as boolean,
      setHideReviewedEntitiesSettings: (hide: boolean) => {
        set(() => ({ hideReviewedEntitiesSettings: hide }));
      },
    }),
    {
      name: 'persisted-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
