import Button from '@mui/joy/Button';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateAbricoDocumentInfo } from 'cloudFunctions/functions';
import { glideDocTypesRawTable } from 'generated/glideDocTypes.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import {
  FICHE_TECHNIQUE_DOC_TYPE_ROW_ID,
  NOTE_DIMENSIONNEMENT_DOC_TYPE_ROW_ID,
  TDocTypeRowId,
} from 'utils/constants';
import { saveDocumentToCloud } from 'utils/save.ts';

type Props = {
  onClose: () => void;
};

const isDocTypeWithSpecificOp = (docTypeRowId: TDocTypeRowId) =>
  docTypeRowId === FICHE_TECHNIQUE_DOC_TYPE_ROW_ID ||
  docTypeRowId === NOTE_DIMENSIONNEMENT_DOC_TYPE_ROW_ID;

const DocTypeSelect = ({ onClose }: Props) => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const dossier = useInteractionsState((state) => state.dossier);
  const currentDocTypeRowId =
    dossier!.analysis!.baseMetaDossierInfo!.glideDocTypeRowId!;

  const [newDocumentTypeRowId, setNewDocumentTypeRowId] =
    useState<TDocTypeRowId>(currentDocTypeRowId as TDocTypeRowId);
  const [newDocOperationRowIds, setNewDocOperationRowIds] = useState<string[]>(
    dossier!.analysis!.baseMetaDossierInfo!.docOperations.map((el) => el.rowId)
  );

  useEffect(() => {
    // We maintain coherence between the doc type and the operations
    if (!isDocTypeWithSpecificOp(newDocumentTypeRowId)) {
      setNewDocOperationRowIds(
        dossier!.analysis!.baseMetaDossierInfo!.chantierOperations.map(
          (el) => el.rowId
        )
      );
    }
  }, [dossier, newDocumentTypeRowId]);

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const currentDocTypeLabel = useMemo(() => {
    const currentDocType = glideDocTypesRawTable.find(
      (el) => el.rowId === currentDocTypeRowId
    );
    return currentDocType?.label;
  }, [currentDocTypeRowId]);

  const handleSaveNewDocType = useCallback(async () => {
    console.log('Changing doc type', {
      newDocOperationRowIds,
      newDocumentTypeRowId,
    });

    setIsSaving(true);
    // We save the document to make sure we are running the processor on the latest version
    await saveDocumentToCloud({
      notify: false,
    });
    useInteractionsState.getState().enableReadOnlyMode();

    try {
      const newOpRowIdsSet = new Set(newDocOperationRowIds);

      await updateAbricoDocumentInfo({
        dossierId: dossier!.id,
        newDocumentTypeRowId: newDocumentTypeRowId,
        newAssociatedOperations:
          dossier!.analysis!.baseMetaDossierInfo!.chantierOperations.filter(
            (el) => newOpRowIdsSet.has(el.rowId)
          ),
      });

      console.log('Document info successfully updated, invalidating dossier');
    } catch (error) {
      console.error(error);
    } finally {
      // We invalidate in all cases, even if we had errors to realign things
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dossier', 'main'] }),
        queryClient.invalidateQueries({ queryKey: ['chantier', 'meta'] }),
      ]);
      useInteractionsState.getState().disableReadOnlyMode();
      setIsSaving(false);
      onClose();
    }
  }, [
    dossier,
    newDocOperationRowIds,
    newDocumentTypeRowId,
    onClose,
    queryClient,
  ]);

  const sortedDocTypesOptions = useMemo(
    () =>
      [...glideDocTypesRawTable].sort((a, b) => a.label.localeCompare(b.label)),
    []
  );

  const sortedOperationsOptions = useMemo(
    () =>
      [...dossier!.analysis!.baseMetaDossierInfo!.chantierOperations].sort(
        (a, b) =>
          a.codeAdeme.localeCompare(b.codeAdeme) ||
          a.rowId.localeCompare(b.rowId)
      ),
    [dossier]
  );

  const handleDocTypeChangeOnSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    handleSaveNewDocType();
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        if (!isSaving) onClose();
      }}
      keepMounted={false}
    >
      <ModalDialog
        onKeyDown={(e) => e.stopPropagation()}
        onMouseMove={(e) => e.stopPropagation()}
      >
        <DialogTitle>{t('docInfoEdit.title')}</DialogTitle>
        <DialogContent>
          {t('docInfoEdit.description', {
            type: currentDocTypeLabel,
          })}
        </DialogContent>

        <form onSubmit={handleDocTypeChangeOnSubmit}>
          <Stack spacing={2}>
            <Select
              disabled={isSaving}
              color="primary"
              value={newDocumentTypeRowId}
              onChange={(_, newValue) =>
                setNewDocumentTypeRowId(newValue as TDocTypeRowId)
              }
              required
              autoFocus
              sx={{ minWidth: 200, maxWidth: 300, fontWeight: 'bold' }}
            >
              {sortedDocTypesOptions.map((el) => (
                <Option key={el.rowId} value={el.rowId}>
                  {el.label}
                </Option>
              ))}
            </Select>

            {isDocTypeWithSpecificOp(newDocumentTypeRowId) && (
              <Select
                disabled={isSaving}
                color="primary"
                value={newDocOperationRowIds}
                required
                autoFocus
                multiple={true}
                onChange={(_, newValue) => setNewDocOperationRowIds(newValue)}
                sx={{ minWidth: 200, maxWidth: 300, fontWeight: 'bold' }}
              >
                {sortedOperationsOptions.map((el) => (
                  <Option key={el.rowId} value={el.rowId}>
                    {`${el.codeAdeme} (${el.rowId})`}
                  </Option>
                ))}
              </Select>
            )}

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={onClose} disabled={isSaving}>
                {t('common.actions.cancel')}
              </Button>
              <Button loading={isSaving} loadingPosition="end" type="submit">
                {isSaving
                  ? t('docInfoEdit.saving')
                  : t('common.actions.validate')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default DocTypeSelect;
