import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DocSectionEntities from 'components/Entity/DocSectionEntities.tsx';
import DocTypeSelect from 'components/Entity/DocTypeSelect.tsx';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { IDocType, IPreparedDocSection } from 'types/index';

type Props = {
  preparedSection: IPreparedDocSection[];
  docType: IDocType;
  docKey: string;
  scrollOffset: number;
  docExtraLabel: string;
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
};

const DocEntities = ({
  preparedSection,
  docType,
  docKey,
  scrollOffset,
  docExtraLabel,
  entityGlobalAutoSelectRef,
}: Props) => {
  const { t } = useTranslation('common');
  const company = useAppState((state) => state.company);
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const [openDocTypeSelect, setOpenDocTypeSelect] =
    React.useState<boolean>(false);
  const sections = useMemo(() => {
    return preparedSection.filter(
      ({ groups }) =>
        company?.featureFlags.handleMissingFields ||
        groups.filter((group) => group.entities.length > 0).length > 0
    );
  }, [preparedSection, company?.featureFlags.handleMissingFields]);

  return (
    <Card sx={{ mb: 1 }}>
      {company!.featureFlags?.handleBackofficeSync && openDocTypeSelect && (
        <DocTypeSelect onClose={() => setOpenDocTypeSelect(false)} />
      )}

      {!company?.featureFlags.handleBackofficeSync && (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          sx={{
            position: 'sticky',
            top: 90,
            alignSelf: 'flex-start',
            width: '100%',
            zIndex: 150000,
            backgroundColor: '#0b0d0e',
            pb: 1,
          }}
        >
          <Typography
            level="title-md"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {`${t(`docTypes.${docType}.name`)} ${docExtraLabel}`}
          </Typography>

          {company?.featureFlags.handleBackofficeSync &&
            useInteractionsState.getState().dossier?.analysis
              ?.baseMetaDossierInfo?.glideDocTypeRowId && (
              <IconButton
                size={'sm'}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDocTypeSelect(true);
                }}
                disabled={isInReadOnlyMode}
                sx={{
                  cursor: isInReadOnlyMode ? 'not-allowed' : 'pointer',
                }}
                onMouseMove={(e) => e.stopPropagation()}
              >
                <EditRoundedIcon />
              </IconButton>
            )}
        </Stack>
      )}

      <Stack>
        {sections.length === 0 ? (
          <Typography level={'body-sm'}>{t('noEntitiesExtracted')}</Typography>
        ) : (
          sections.map(({ sectionName, groups, missingEntities }) => (
            <DocSectionEntities
              key={sectionName}
              {...{
                sectionName,
                groups,
                missingEntities,
                docKey,
                scrollOffset,
                entityGlobalAutoSelectRef,
              }}
            />
          ))
        )}
      </Stack>
    </Card>
  );
};

export default DocEntities;
