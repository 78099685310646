import { Core } from '@pdftron/webviewer';

import { getDossierById } from 'queries/dossiers.ts';
import { useAppState } from 'stores/appStore.ts';
import { getDossierPDFBytes } from 'utils/dossierHelpers.ts';

const getPdfBytes = async (dossierId: string) => {
  const dossier = await getDossierById(dossierId);
  if (!dossier) {
    throw new Error('Dossier not found');
  }
  return await getDossierPDFBytes(dossier);
};

export const openOtherDocument = async (dossierId: string) => {
  const instance = await useAppState.getState().getInstance();
  const multiViewerEnabled = useAppState.getState().multiViewerEnabled;
  const setMultiViewerEnabled = useAppState.getState().setMultiViewerEnabled;

  const pdfBytesPromise = getPdfBytes(dossierId);

  const getSecondViewer = async (): Promise<Core.DocumentViewer> => {
    return new Promise((resolve) => {
      if (multiViewerEnabled) {
        const viewer = instance.Core.getDocumentViewers()[1];
        viewer.closeDocument();
        return resolve(viewer);
      }

      const { UI } = instance;

      const eventHandler = async () => {
        UI.removeEventListener(UI.Events.MULTI_VIEWER_READY, eventHandler);
        setMultiViewerEnabled(true);
        const [, secondDocViewer] = instance.Core.getDocumentViewers();
        const secondViewerElement = secondDocViewer.getViewerElement();
        const header =
          secondViewerElement.ownerDocument.getElementById('header2')!;
        const [controlButtons] =
          header.getElementsByClassName('control-buttons')!;
        header.removeChild(controlButtons);

        const button = document.createElement('button');
        button.className = 'close-button';
        button.innerText = 'Fermer';
        button.onclick = () => {
          UI.exitMultiViewerMode();
          setMultiViewerEnabled(false);
        };
        header.appendChild(button);
        resolve(secondDocViewer);
      };

      UI.addEventListener(UI.Events.MULTI_VIEWER_READY, eventHandler);
      UI.enterMultiViewerMode();
    });
  };

  const secondViewer = await getSecondViewer();

  await secondViewer.loadDocument(await pdfBytesPromise, { filename: '' });
  secondViewer.enableReadOnlyMode();
};
