import Add from '@mui/icons-material/Add';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import { createNewTag } from 'queries/tags';
import { useAppState } from 'stores/appStore.ts';

// We use a uuid to make a unique value for the add option
const addListValue = uuidv4();

const SelectList = ({
  selectedTagId,
  setSelectedTagId,
}: {
  selectedTagId: string;
  setSelectedTagId: (value: string) => void;
}) => {
  const { t } = useTranslation('common');
  const { company, tags } = useAppState(
    useShallow((state) => ({ company: state.company, tags: state.tags }))
  );
  const companyId = company?.id;
  const [openCreateTag, setOpenCreateTag] = React.useState<boolean>(false);
  const [newTagName, setNewTagName] = React.useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: { name: string; companyId: string }) =>
      await createNewTag(data.companyId, data.name),
    onSuccess: async ({ id }) => {
      await useAppState.getState().refreshCompanyTags();
      setSelectedTagId(id);
      setOpenCreateTag(false);
    },
    onError: (error) => {
      console.error('Error creating new tag:', error);
      alert(t('tags.error'));
    },
  });

  const handleCreateTag = () =>
    companyId && mutate({ name: newTagName, companyId: companyId });

  return (
    <Stack spacing={2}>
      <Select
        defaultValue={selectedTagId}
        renderValue={(selected) => {
          if (!selected) return;
          else if (selected.value === null && !selectedTagId) return;
          const tagId = selectedTagId || selected.value;
          const tagName = tags.find((tag) => tag.id === tagId)?.name;
          return (
            <Box sx={{ display: 'flex', gap: '0.25rem' }}>
              <Chip variant="soft" color="primary">
                {tagName}
              </Chip>
            </Box>
          );
        }}
        onChange={(
          // @ts-expect-error, event is not used
          event: React.SyntheticEvent | null,
          newValue: string | null
        ) =>
          newValue !== addListValue &&
          newValue !== null &&
          setSelectedTagId(newValue)
        }
        sx={{
          minWidth: '15rem',
        }}
        slotProps={{
          listbox: {
            sx: {
              width: '100%',
            },
          },
        }}
      >
        {tags.map((tag) => (
          <Option key={tag.id} value={tag.id}>
            {tag.name}
          </Option>
        ))}
        <Option key={addListValue} value={addListValue}>
          <Button
            variant="outlined"
            color="primary"
            startDecorator={<Add />}
            onClick={() => setOpenCreateTag(true)}
          >
            {t('tags.add')}
          </Button>
        </Option>
      </Select>
      {openCreateTag && (
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <FormControl>
            <FormLabel> {t('tags.createName')}</FormLabel>
            <Input
              value={newTagName}
              autoFocus
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTagName(e.target.value);
              }}
            />
          </FormControl>

          <Button
            loading={isPending}
            variant="outlined"
            onClick={handleCreateTag}
          >
            {t('common.actions.create')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default SelectList;
