import { storeNewFileWithMetadata } from 'queries/storage';

export const savePDFInBucket = async (
  pdfBytes: Uint8Array,
  filePath: string,
  metadata: Record<string, any>
) => {
  const blob = new Blob([pdfBytes]);

  return await storeNewFileWithMetadata(blob, filePath, metadata);
};

export const downloadFile = (fileData: ArrayBuffer, fileName: string) => {
  const blob = new Blob([fileData], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};
