import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { updateProfile } from 'firebase/auth';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReviewStatusSelect from 'components/Review/ReviewStatusSelect.tsx';
import { useAppState } from 'stores/appStore.ts';

export function ReviewerInfo() {
  const { t } = useTranslation();

  const user = useAppState((state) => state.user);

  const [showInput, setShowInput] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await updateProfile(user!, { displayName });
      setShowInput(false);
      enqueueSnackbar(t('review.reviewer.saveNotification.success'), {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(t('review.reviewer.saveNotification.error'), {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  }, [displayName, t, user]);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      useFlexGap
      sx={{ flexWrap: 'wrap' }}
    >
      <ReviewStatusSelect></ReviewStatusSelect>
      {!showInput && (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <PersonIcon fontSize="small"></PersonIcon>{' '}
          {user?.displayName ? (
            <Typography level={'body-sm'}>{user.displayName}</Typography>
          ) : (
            <>
              <WarningAmberIcon
                // @ts-ignore
                color={'danger'}
              ></WarningAmberIcon>
              {t('review.reviewer.missing')}
            </>
          )}
          <IconButton size="sm" onClick={() => setShowInput(!showInput)}>
            <EditIcon></EditIcon>
          </IconButton>
        </Stack>
      )}

      {showInput && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'end'}
          gap={1}
        >
          <Input
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            disabled={isSaving}
          ></Input>
          <IconButton disabled={!displayName || isSaving} onClick={handleSave}>
            <SaveIcon></SaveIcon>
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}
