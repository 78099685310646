// Inspired by https://github.com/Hopding/pdf-lib/issues/905
import { Core } from '@pdftron/webviewer';
import { PageSizes, PDFDict, PDFDocument, PDFName, PDFRef } from 'pdf-lib';

import { getPDFNet } from 'utils/apryze.ts';

const readFileAsArrayBuffer = async (file: File): Promise<Uint8Array> => {
  const arrayBuffer = await file.arrayBuffer();
  return new Uint8Array(arrayBuffer);
};

const getPDFDocFromImageFile = async (
  image: File
): Promise<Core.PDFNet.PDFDoc> => {
  // This code uses pdf-lib internally because it was coded first like this,
  // we might want to switch it entirely to PDFNet in the future
  const pdfDoc = await PDFDocument.create();

  let imagePdf = null;
  if (image.type === 'image/jpeg') {
    imagePdf = await pdfDoc.embedJpg(await readFileAsArrayBuffer(image));
  } else if (image.type === 'image/png') {
    imagePdf = await pdfDoc.embedPng(await readFileAsArrayBuffer(image));
  } else {
    throw new Error(`Image type ${image.type} NOT supported`);
  }

  // We resize the page so that the image fits on it and the width is the one
  // of an A4 page
  let imageDims = imagePdf.size();
  const ratio = imageDims.width / imageDims.height;
  const pageDimensions: [number, number] = [
    PageSizes.A4[0],
    PageSizes.A4[0] / ratio,
  ];

  const pdfPage = pdfDoc.addPage(pageDimensions);

  const { width, height } = pdfPage.getSize();
  // Make sure the image is not larger than the page, and scale down to fit if it is
  if (imageDims.width > width || imageDims.height > height) {
    imageDims = imagePdf.scaleToFit(width, height);
  }

  // Draw image in page, centered horizontally and vertically
  pdfPage.drawImage(imagePdf, {
    x: width / 2 - imageDims.width / 2,
    y: height / 2 - imageDims.height / 2,
    width: imageDims.width,
    height: imageDims.height,
  });

  // We convert it to a PDFNet document
  const PDFNet = await getPDFNet();
  return await PDFNet.PDFDoc.createFromBuffer(await pdfDoc.save());
};
/**
 * Helper method that will rescale the width of the pages to the a4 width
 */
export const rescalePagesWidthToA4 = async (
  doc: Core.PDFNet.PDFDoc,
  pagesIdx: number[]
) => {
  doc.lock();
  for (const pageNumber of pagesIdx) {
    const page = await doc.getPage(pageNumber);
    const width = await page.getPageWidth();

    // rotation is already taken into account in width,
    // so we don't need to do a fancy logic here
    const ratio = PageSizes.A4[0] / width;

    await page.scale(ratio);
  }
};
export const getPDFDocFromPdfFile = async (
  file: File,
  forceA4: boolean = true
): Promise<Core.PDFNet.PDFDoc> => {
  const PDFNet = await getPDFNet();
  const doc = await PDFNet.PDFDoc.createFromBuffer(
    await readFileAsArrayBuffer(file)
  );

  if (forceA4) {
    await rescalePagesWidthToA4(
      doc,
      [...Array(await doc.getPageCount()).keys()].map((i) => i + 1)
    );
  }
  return doc;
};
export const mergePDFs = async (
  pdfs: Core.PDFNet.PDFDoc[]
): Promise<Core.PDFNet.PDFDoc> => {
  const PDFNet = await getPDFNet();
  const newDoc = await PDFNet.PDFDoc.create();

  for (const currentPdf of pdfs) {
    const copiedPages: Core.PDFNet.Page[] = [];
    for (
      const itr = await currentPdf.getPageIterator();
      await itr.hasNext();
      await itr.next()
    ) {
      copiedPages.push(await itr.current());
    }

    const importedPages = await newDoc.importPages(copiedPages);
    for (const page of importedPages) {
      await newDoc.pagePushBack(page);
    }
  }

  return newDoc;
};
export const mergeFiles = async (files: FileList): Promise<Uint8Array> => {
  const pdfs = [];

  for (const file of files) {
    if (file.type === 'application/pdf') {
      pdfs.push(await getPDFDocFromPdfFile(file));
    } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
      pdfs.push(await getPDFDocFromImageFile(file));
    } else {
      throw new Error(`File type ${file.type} NOT supported`);
    }
  }
  const PDFNet = await getPDFNet();
  const mergedPDF = await mergePDFs(pdfs);
  return await removeApryseWatermark(
    await mergedPDF.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_remove_unused)
  );
};
/**
 * Helper method to remove the Apryse watermark from the document
 */
export const removeApryseWatermark = async (
  fileData: Uint8Array,
  shouldHaveWatermark: boolean = true
): Promise<Uint8Array> => {
  // We duplicate the bytes to avoid issues
  const pdfDoc = await PDFDocument.load(Uint8Array.from(fileData));
  let hadWatermark = pdfDoc.getPageIndices().length === 0;

  pdfDoc.getPages().forEach((page) => {
    const cleanPage = (obj: PDFDict) => {
      // the watermark is always identified by the same nasty key
      const watermarkRef = obj.get(PDFName.of('Trn3dK9'));
      // if we find it, we delete it :tada:
      if (watermarkRef instanceof PDFRef) {
        page.node.Resources()!.context.delete(watermarkRef);
        hadWatermark = true;
      }
    };

    const cleanXobj = (xobj: PDFDict | PDFRef | any, depth = 0): void => {
      // We avoid infinite loops
      if (depth > 20) {
        console.error('Too deep, stopping');
        return;
      }
      if (xobj instanceof PDFDict) {
        cleanPage(xobj);
      } else if (xobj instanceof PDFRef) {
        const realRefAndXobj = page.node
          .Resources()!
          .context.enumerateIndirectObjects()
          .find(([ref]) => ref === xobj);
        if (realRefAndXobj) {
          return cleanXobj(realRefAndXobj[1], depth + 1);
        }
      }
    };

    const start = page.node.Resources()?.get(PDFName.XObject);
    cleanXobj(start);
  });
  if (shouldHaveWatermark && !hadWatermark) {
    console.error(
      'No Apryse watermark found in the document, this is unexpected'
    );
  }
  return await pdfDoc.save({ useObjectStreams: false });
};
