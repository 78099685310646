import FiberNewIcon from '@mui/icons-material/FiberNew';
import HomeIcon from '@mui/icons-material/Home';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { saveMetaDossier } from 'cloudFunctions/functions.ts';
import { MarkdownEditor } from 'components/Editor/MarkdownEditor.tsx';
import DossierStatusChip from 'components/Review/DossierStatusChip.tsx';
import SelectPhaseExtrasDocs from 'components/Review/SelectPhaseExtrasDocs.tsx';
import FullScreenLoader from 'components/UI/FullScreenLoader.tsx';
import { queryClient } from 'queries';
import { getChantierInfo } from 'queries/chantier.ts';
import { useAppState } from 'stores/appStore.ts';
import { useChantierState } from 'stores/chantierStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { REVIEW_PHASES_ORDERED, TDossierPhase } from 'types/index';
import {
  glideDocInfoByGlideDocTypesRowId,
  labelByGlideDocTypesRowId,
} from 'utils/constants.ts';

export function HomeEmail() {
  const { t } = useTranslation();
  useQuery(getChantierInfo());

  useEffect(() => {
    useChantierState.getState().resetState();
  }, []);

  const user = useAppState((state) => state.user);
  const urlParams = useAppState((state) => state.urlParams);
  const hasChangesToSave = useAppState((state) => state.hasChangesToSave);

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const note = useChantierState(
    (state) => state.chantierInternalReviewNotesMarkdown
  );

  const remoteChantierNote = useChantierState(
    (state) => state.remoteChantierInternalReviewNotesMarkdown
  );
  const setChantierNote = useChantierState(
    (state) => state.setChantierInternalReviewNotesMarkdown
  );

  const markdownFeedbackByDocRowId = useChantierState(
    useShallow((state) => state.markdownFeedbackByDocRowId ?? {})
  );

  const metaDossierForChantier = useChantierState(
    (state) => state.metaDossierForChantier
  );
  const remoteCurrentPhaseMissingDocTypes = useChantierState(
    (state) => state.remoteCurrentPhaseMissingDocTypes
  );

  const nextPhase = useMemo<TDossierPhase | null>(() => {
    const currentPhase = metaDossierForChantier?.dossierPhase;

    // @ts-ignore
    const currentPhaseIndex = REVIEW_PHASES_ORDERED.indexOf(currentPhase);
    return (
      REVIEW_PHASES_ORDERED[currentPhaseIndex + 1] ??
      (null as TDossierPhase | null)
    );
  }, [metaDossierForChantier]);

  const { phaseDocs, otherPhaseDocs } = useChantierState(
    (state) => state.computedChantierDocs
  );
  const currentPhaseIsValid = useChantierState(
    (state) => state.currentPhaseIsValid
  );

  const [sendEmailArtisan, setSendEmailArtisan] = useState(false);
  const [sendEmailBenef, setSendEmailBenef] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const concernedDocs = useMemo(() => {
    if (urlParams.dossierId) {
      return otherPhaseDocs.filter(
        (doc) => doc.dossierId === urlParams.dossierId
      );
    } else {
      return phaseDocs;
    }
  }, [phaseDocs, otherPhaseDocs, urlParams]);

  const saveAndSend = useCallback(
    async ({
      saveOnly,
      forceSendAllToUser,
    }: {
      saveOnly: boolean;
      forceSendAllToUser: boolean;
    }) => {
      try {
        setIsLoading(true);
        const chantierState = useChantierState.getState();
        await saveMetaDossier({
          retrieveParams: {
            mode: 'fromChantierId',
            chantierRowId: urlParams.chantierId!,
          },
          currentPhaseExtraDocTypes:
            chantierState.currentPhaseExtraDocTypes ?? [],
          reviews: {
            sendArtisanEmail:
              saveOnly || forceSendAllToUser ? false : sendEmailArtisan,
            sendBenefEmail:
              saveOnly || forceSendAllToUser ? false : sendEmailBenef,
            forceSendAllToEmail:
              !saveOnly && forceSendAllToUser && user?.email
                ? user?.email
                : null,
            forDocs: concernedDocs.map((doc) => ({
              dossierId: doc.dossierId,
              rowId: doc.rowId,
              isValid: doc.isValid,
              reviewerName: useAppState.getState().user!.displayName,
              reviewFeedbackMarkdown:
                useChantierState.getState().markdownFeedbackByDocRowId![
                  doc.rowId
                ],
            })),
          },
        });
        setSendEmailBenef(false);
        setSendEmailArtisan(false);
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['dossier', 'meta'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['chantier', 'meta'],
          }),
        ]);
        enqueueSnackbar('Bien enregistré', { variant: 'success' });
      } finally {
        setIsLoading(false);
      }
    },
    [
      concernedDocs,
      sendEmailArtisan,
      sendEmailBenef,
      urlParams.chantierId,
      user?.email,
    ]
  );

  const moveToNextPhase = useCallback(async () => {
    if (!nextPhase) return;
    try {
      setIsLoading(true);
      await saveMetaDossier({
        retrieveParams: {
          mode: 'fromChantierId',
          chantierRowId: urlParams.chantierId!,
        },
        moveToReviewPhase: nextPhase,
      });
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['dossier', 'meta'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['chantier', 'meta'],
        }),
      ]);
      enqueueSnackbar('Mission Success', { variant: 'success' });
      useAppState
        .getState()
        .navigate(
          `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}`
        );
    } finally {
      setIsLoading(false);
    }
  }, [nextPhase, urlParams.chantierId, urlParams.companyId]);

  const concernedDocsWithSetter = useMemo(() => {
    return concernedDocs.map((doc) => ({
      ...doc,
      setReviewFeedbackMarkdown: (note: string | null) => {
        useChantierState.getState().setMarkdownFeedbackReview(doc.rowId, note);
      },
    }));
  }, [concernedDocs]);

  const hasBenefDocs = concernedDocs.some((doc) =>
    glideDocInfoByGlideDocTypesRowId[doc.glideDocTypeRowId].owners.some(
      (owner) => owner === 'beneficiaire'
    )
  );

  const hasArtisanDocs = concernedDocs.some((doc) =>
    glideDocInfoByGlideDocTypesRowId[doc.glideDocTypeRowId].owners.some(
      (owner) => owner === 'artisan'
    )
  );

  useEffect(() => {
    if (!hasArtisanDocs) {
      setSendEmailArtisan(false);
    }
  }, [hasArtisanDocs]);

  useEffect(() => {
    if (!hasBenefDocs) {
      setSendEmailBenef(false);
    }
  }, [hasBenefDocs]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Stack sx={{ width: '100vw', height: '100vh' }} gap={2} padding={2}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <IconButton
              onClick={() =>
                useAppState
                  .getState()
                  .navigate(
                    `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}`
                  )
              }
            >
              <HomeIcon />
            </IconButton>
            <Typography level={'title-lg'}>Envoie d&apos;emails</Typography>
            <DossierStatusChip />
            <SelectPhaseExtrasDocs variant={'button'} />
          </Stack>

          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Button
              onClick={() =>
                saveAndSend({ saveOnly: true, forceSendAllToUser: false })
              }
              disabled={!hasChangesToSave}
            >
              Enregistrer
            </Button>
            <Button
              endDecorator={<RocketLaunchIcon />}
              disabled={!(currentPhaseIsValid === true && nextPhase !== null)}
              onClick={moveToNextPhase}
            >
              Phase suivante
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gap={2}
          marginTop={3}
          justifyContent={'center'}
        >
          <Stack
            direction={'column'}
            gap={1}
            width={'100%'}
            maxWidth={1000}
            sx={{ overflowY: 'auto', height: '100%' }}
          >
            <Card>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {t('review.internalChantierNote.title')}
                {note !== remoteChantierNote && <FiberNewIcon />}
              </Stack>

              <MarkdownEditor
                value={note}
                onChange={setChantierNote}
                readOnly={isInReadOnlyMode}
                placeholder={''}
              ></MarkdownEditor>
            </Card>
            {(remoteCurrentPhaseMissingDocTypes ?? []).length > 0 &&
              remoteCurrentPhaseMissingDocTypes!.map((docType, idx) => (
                <Card color={'danger'} key={idx} variant={'soft'}>
                  <Typography level={'title-md'}>
                    {labelByGlideDocTypesRowId[docType]}{' '}
                    <b>[{t(`review.status.missing`)}]</b>
                  </Typography>
                </Card>
              ))}

            {concernedDocsWithSetter.map((doc) => (
              <Card
                key={doc.rowId}
                color={
                  doc.reviewStatus === 'pending'
                    ? 'neutral'
                    : doc.reviewStatus === 'valid'
                      ? 'success'
                      : 'danger'
                }
                variant={'soft'}
              >
                <Typography level={'title-md'}>
                  {doc.label} <b>[{t(`review.status.${doc.reviewStatus}`)}]</b>
                </Typography>
                {doc.reviewStatus === 'invalid' && (
                  <MarkdownEditor
                    readOnly={isInReadOnlyMode}
                    value={markdownFeedbackByDocRowId[doc.rowId] ?? ''}
                    onChange={doc.setReviewFeedbackMarkdown}
                    placeholder={''}
                  ></MarkdownEditor>
                )}
              </Card>
            ))}
          </Stack>
          <Card sx={{ height: 'fit-content' }}>
            <Stack direction={'column'} gap={2}>
              <Typography level={'title-lg'}>Emails</Typography>

              {hasArtisanDocs ? (
                <Checkbox
                  label={"Notifier l'artisan"}
                  checked={sendEmailArtisan}
                  onChange={(e) => setSendEmailArtisan(e.target.checked)}
                ></Checkbox>
              ) : (
                <Typography>{"L'artisan n'est pas concerné"}</Typography>
              )}
              {hasBenefDocs ? (
                <Checkbox
                  label={'Notifier le⋅la bénéficiaire'}
                  checked={sendEmailBenef}
                  onChange={(e) => setSendEmailBenef(e.target.checked)}
                ></Checkbox>
              ) : (
                <Typography>{"Le benef n'est pas concerné"}</Typography>
              )}
              <Button
                disabled={!(sendEmailBenef || sendEmailArtisan)}
                onClick={() =>
                  saveAndSend({ saveOnly: false, forceSendAllToUser: false })
                }
              >
                Envoyer
              </Button>
              <Tooltip title={user?.email ?? ''}>
                <Button
                  variant={'outlined'}
                  disabled={!(hasArtisanDocs || hasBenefDocs)}
                  onClick={() =>
                    saveAndSend({ saveOnly: false, forceSendAllToUser: true })
                  }
                >
                  Envoi(s) test(s) à mon addresse
                </Button>
              </Tooltip>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}
