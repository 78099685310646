import BlurOn from '@mui/icons-material/BlurOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const IconByStatus = {
  null: <></>,
  pending: <BlurOn sx={{ fontSize: '1.5rem' }} color={'info'} />,
  invalid: <CancelIcon sx={{ fontSize: '1.5rem' }} color={'warning'} />,
  valid: <CheckRoundedIcon sx={{ fontSize: '1.5rem' }} color={'success'} />,
};

export default IconByStatus;
