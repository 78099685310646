import { httpsCallable } from 'firebase/functions';
import { z } from 'zod';

import { functions } from 'config/firebaseInit';
import {
  TAnalysisResponse,
  TMetaDossierBaseForChantier,
  TMetaDossierResponse,
  TUpdateAbricoDocumentInfo,
  ZGenerateReviewFeedbackBody,
  TSaveMetaDossier,
  TAttestationResponse,
} from 'types/index';

export const invokeSplitterAndExtractorWorkflow = httpsCallable(
  functions,
  'invokeWorkflow-docSplitterAndExtractor',
  { timeout: 300000 }
);

export const getAnalysis = httpsCallable<
  { dossierId: string },
  TAnalysisResponse
>(functions, 'documentAI-getAnalysis', {
  timeout: 100000,
});

export const getMetaDossierInfo = httpsCallable<
  { dossierId: string },
  TMetaDossierResponse | null
>(functions, 'documentAI-getMetaDossierInfo', {
  timeout: 100000,
});

export const reloadMetaDossier = httpsCallable<
  | { mode: 'fromDossierId'; dossierId: string }
  | { mode: 'fromChantierId'; chantierRowId: string },
  void
>(functions, 'stationHelpers-reloadMetaDossier');

export const getChantierMetaDossier = httpsCallable<
  { mode: 'fromChantierId'; chantierRowId: string },
  TMetaDossierBaseForChantier
>(functions, 'stationHelpers-getChantierMetaDossier');

export const updateAbricoDocumentInfo =
  httpsCallable<TUpdateAbricoDocumentInfo>(
    functions,
    'stationHelpers-updateAbricoDocInfo'
  );

export const generateReviewFeedback = httpsCallable<
  z.infer<typeof ZGenerateReviewFeedbackBody>,
  { succeeded: boolean; reviewFeedbackMarkdown: string }
>(functions, 'stationHelpers-generateReviewFeedback');

export const saveMetaDossier = httpsCallable<TSaveMetaDossier>(
  functions,
  'stationHelpers-saveMetaDossier'
);

export const getAttestationsInfo = httpsCallable<
  { siret: string; codes_ademe: string[] },
  TAttestationResponse
>(functions, 'artisans_rge__get_attestations');
