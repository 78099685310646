import { useEffect } from 'react';

import { useAppState } from 'stores/appStore.ts';

// Make sure we log only once we TrackTiming component (useState is not reactive enough)
const tracker = new Set();

export function TrackTiming({ name }: { name: string }) {
  const logDurationInFs = useAppState((state) => state.logDurationInFs);
  useEffect(() => {
    if (!tracker.has(name)) {
      tracker.add(name);
      logDurationInFs({ durationMs: performance.now(), name });
    }
  }, [name, logDurationInFs]);
  return <></>;
}
