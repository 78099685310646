import { InfoOutlined } from '@mui/icons-material';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ReplayIcon from '@mui/icons-material/Replay';
import { FormHelperText, Textarea } from '@mui/joy';
import Autocomplete from '@mui/joy/Autocomplete';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { TSyncValue, TWipMetaEl } from 'types/index';
import {
  capitalizeFirstLetterOfEachWord,
  findMostCommonWords,
} from 'utils/string.ts';

function SyncEntityElErrors({ el }: { el: TWipMetaEl }) {
  if (el.errors.length === 0) {
    return <></>;
  }
  return (
    <FormHelperText
      sx={{ maxWidth: { md: 300, sm: 100 }, ml: 2, mt: 0.5 }}
      color={'danger'}
    >
      {/* @ts-ignore */}
      <InfoOutlined color={'danger'} fontSize="small" sx={{ mr: 0.5 }} />
      {el.errors.map((error) => (
        <Typography level={'body-xs'} key={error} color={'danger'}>
          {error}
        </Typography>
      ))}
    </FormHelperText>
  );
}

function SyncEntityElLabel({ el }: { el: TWipMetaEl }) {
  return (
    <FormLabel sx={{ p: 0, m: 0, pt: 0.2, pb: 0.2, maxWidth: 200 }}>
      {el.label}
    </FormLabel>
  );
}

function SyncEntityElNumber({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: number = parseFloat(e.target.value);
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, isNaN(value) ? null : value);
    },
    [el.metaPath]
  );

  return (
    <FormControl size={'sm'}>
      <SyncEntityElLabel el={el} />
      <Input
        type={'number'}
        value={(el.value as number) || ''}
        variant={'solid'}
        disabled={isInReadOnlyMode || el.isReadOnlyStation}
        onChange={onChange}
        error={el.errors.length > 0}
        sx={{ width: { md: 250, xs: 100 } }}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElStringEmail({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, value || null);
    },
    [el.metaPath]
  );

  return (
    <FormControl size={'sm'}>
      <SyncEntityElLabel el={el} />
      <Input
        value={(el.value as string) || ''}
        variant={'solid'}
        disabled={isInReadOnlyMode || el.isReadOnlyStation}
        onChange={onChange}
        error={el.errors.length > 0}
        sx={{ minWidth: { md: 200, xs: 100 } }}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElString({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChangeTextArea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, e.target.value || null);
    },
    [el.metaPath]
  );

  const handleChangeSelect = useCallback(
    // @ts-ignore
    (_, newValue: string | null) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, newValue || null);
    },
    [el.metaPath]
  );

  if (Array.isArray(el.stringOptions)) {
    return (
      <FormControl size={'sm'}>
        <SyncEntityElLabel el={el} />

        <Autocomplete
          options={el.stringOptions}
          onChange={handleChangeSelect}
          sx={{
            margin: 1 / 2,
            width: { md: '250px!important', xs: '100px!important' },
          }}
          size={'sm'}
          value={(el.value as string) || ''}
          disabled={isInReadOnlyMode || el.isReadOnlyStation}
          variant={'solid'}
          color={el.errors.length > 0 ? 'danger' : 'neutral'}
        ></Autocomplete>
      </FormControl>
    );
  }

  return (
    <FormControl size={'sm'}>
      <SyncEntityElLabel el={el} />
      <Textarea
        variant={'solid'}
        sx={{ minWidth: { md: 200, xs: 100 } }}
        size={'sm'}
        value={(el.value as string) || ''}
        disabled={isInReadOnlyMode || el.isReadOnlyStation}
        onChange={onChangeTextArea}
        error={el.errors.length > 0}
      ></Textarea>
    </FormControl>
  );
}

function SyncEntityElDateTime({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // We try to reuse the time part of the remote value (if present)
      // At the moment we only manage the date part, as we don't have the need to handle time too
      const extraTsPart = ((el.remoteValue as string) || '').includes('T')
        ? ((el.remoteValue as string) || '').split('T')[1]
        : '00:00:00.000Z';

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaPath,
          e.target.value ? `${e.target.value}T${extraTsPart}` : null
        );
    },
    [el.metaPath, el.remoteValue]
  );

  return (
    <FormControl size={'sm'}>
      <SyncEntityElLabel el={el} />
      <Input
        type={'date'}
        value={((el.value as string) || '').split('T')[0]}
        variant={'solid'}
        disabled={isInReadOnlyMode || el.isReadOnlyStation}
        onChange={onChange}
        error={el.errors.length > 0}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElBoolean({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const valueByString = {
        true: true,
        false: false,
        null: null,
      } as const;

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaPath,
          valueByString[e.target.value as 'true' | 'false' | 'null']
        );
    },
    [el.metaPath]
  );

  return (
    <FormControl size={'sm'}>
      <SyncEntityElLabel el={el} />
      <Card
        variant={isInReadOnlyMode ? 'soft' : 'solid'}
        color={'neutral'}
        sx={{ m: 0, p: 0, pl: 1, pr: 1 }}
      >
        <RadioGroup
          value={el.value}
          onChange={onChange}
          orientation={'horizontal'}
          size={'sm'}
        >
          <Radio
            value="true"
            label="Oui"
            variant="outlined"
            disabled={isInReadOnlyMode || el.isReadOnlyStation}
          />
          <Radio
            value="false"
            label="Non"
            variant="outlined"
            disabled={isInReadOnlyMode || el.isReadOnlyStation}
          />
          <Radio
            value="null"
            label="?"
            variant="outlined"
            disabled={isInReadOnlyMode || el.isReadOnlyStation}
          />
        </RadioGroup>
      </Card>
    </FormControl>
  );
}

export function EditSyncElement({ el }: { el: TWipMetaEl }) {
  switch (el.type) {
    case 'string':
      return <SyncEntityElString el={el} />;
    case 'number':
      return <SyncEntityElNumber el={el} />;
    case 'date-time':
      return <SyncEntityElDateTime el={el} />;
    case 'boolean':
      return <SyncEntityElBoolean el={el} />;
    case 'email-address':
      return <SyncEntityElStringEmail el={el} />;
    default:
      return <Typography>Unsupported type</Typography>;
  }
}

function cleanDocText(el: TWipMetaEl, docText?: string): TSyncValue {
  if (!docText) {
    return null;
  }

  if (el.type === 'string') {
    if (Array.isArray(el.stringOptions)) {
      // We try to look for the best option
      return findMostCommonWords(docText, el.stringOptions);
    } else {
      return docText.trim();
    }
  } else if (el.type === 'number') {
    // We split on alphanumerical chracters to skip units
    const cleanedValue = docText
      .split(/[A-Za-z]+/)[0]
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.');
    const parsedValue = parseFloat(cleanedValue);
    if (isNaN(parsedValue)) {
      return null;
    }
    return parsedValue;
  } else if (el.type === 'date-time') {
    // we don't support parsing date-time from docText
    return null;
  } else if (el.type === 'boolean') {
    // we don't support parsing boolean from docText
    return null;
  } else if (el.type === 'email-address') {
    return docText.trim();
  } else {
    throw new Error('Unsupported type: ${el.type}');
  }
}

function SyncEntityElEntry({
  docText,
  entry,
}: {
  docText?: string;
  entry: TWipMetaEl;
}) {
  const possiblyTransferredValue = useMemo(() => {
    return cleanDocText(entry, docText);
  }, [docText, entry]);

  const handleTransferValue = useCallback(
    (e: MouseEvent) => {
      let valueToTransfer: TSyncValue = possiblyTransferredValue;
      if (typeof valueToTransfer === 'string') {
        if (e.shiftKey) {
          valueToTransfer = valueToTransfer.toLowerCase();
        } else if (e.ctrlKey) {
          valueToTransfer = capitalizeFirstLetterOfEachWord(valueToTransfer);
        }
      }

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(entry.metaPath, valueToTransfer);
    },
    [entry.metaPath, possiblyTransferredValue]
  );

  const handleResetValue = useCallback(() => {
    useInteractionsState
      .getState()
      .setMetaDossierWipEl(entry.metaPath, entry.remoteValue);
  }, [entry.metaPath, entry.remoteValue]);

  return (
    <Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
        spacing={0.5}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Avatar
            src="/abrico_letter.svg"
            sx={{
              '--Avatar-size': '1.5rem',
              mr: 1,
              mt: 3,
            }}
          />
          <EditSyncElement el={entry} />
        </Stack>
        <IconButton
          size={'sm'}
          disabled={!possiblyTransferredValue}
          sx={{ ml: '4px', mt: 3 }}
          onClick={handleTransferValue}
        >
          <MoveDownIcon sx={{ transform: 'scale(-1, 1)' }} />
        </IconButton>
        <IconButton
          size={'sm'}
          sx={{ mt: 3 }}
          disabled={entry.value === entry.remoteValue}
          onClick={handleResetValue}
        >
          <ReplayIcon />
        </IconButton>
        {/* We hack around some spacing */}
        <Box width={{ md: '58px', xs: 0 }}></Box>
      </Stack>
      <Stack direction={'row'} alignItems={'flex-end'}>
        {/* We hack around some spacing */}
        <SyncEntityElErrors el={entry} />
        <Box width={{ md: '58px', xs: 0 }}></Box>
      </Stack>
    </Box>
  );
}

export function SyncEntityEl({
  entityId,
  docText,
}: {
  entityId: string;
  docText?: string;
}) {
  const links = useInteractionsState(
    useShallow((state) =>
      state.metaDossierInfo &&
      Array.isArray(state.metaDossierInfo.metaDossierLinkByAnnotId[entityId])
        ? state.metaDossierInfo.metaDossierLinkByAnnotId[entityId]
        : []
    )
  );

  const elements = useInteractionsState(
    useShallow((state) =>
      links.map(
        (link) =>
          state.metaDossierWipElByMetaPath.get(link.metaDossierDataPath)!
      )
    )
  );

  if (!links.length) {
    return <></>;
  }

  return (
    <>
      <Stack
        // Prevent event from bubbling to EntityCard which would cause an auto-select to happen
        onKeyDown={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          useUiState.getState().setFocusedEntityId(entityId, false);
        }}
        onMouseMove={(e) => e.stopPropagation()}
        direction={'column'}
        width={'100%'}
        alignItems={'end'}
        gap={0.5}
      >
        {elements.map((el) => (
          <SyncEntityElEntry key={el.metaPath} docText={docText} entry={el} />
        ))}
      </Stack>
    </>
  );
}
