import EmailIcon from '@mui/icons-material/Email';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { saveMetaDossier } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import { useAppState } from 'stores/appStore.ts';
import { useChantierState } from 'stores/chantierStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { TSaveMetaDossier } from 'types/index';
import { saveDocumentToCloud } from 'utils/save.ts';

const goNextDoc = () => {
  const { phaseDocs } = useChantierState.getState().computedChantierDocs;
  const urlParams = useAppState.getState().urlParams;

  const navigate = useAppState.getState().navigate;

  const nextDoc = phaseDocs.find(
    (_, idx, arr) => arr[idx - 1]?.dossierId === urlParams.dossierId
  );
  if (nextDoc) {
    navigate(nextDoc.url);
  } else {
    navigate(
      `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/email`
    );
  }
};

export function MasterNavigation() {
  const { t } = useTranslation();
  const hasChangesToSave = useAppState((state) => state.hasChangesToSave);
  const urlParams = useAppState((state) => state.urlParams);
  const navigate = useAppState((state) => state.navigate);
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const [isSaving, setIsSaving] = useState(false);
  const saveEverything = useCallback(async () => {
    const interactionsState = useInteractionsState.getState();
    const chantierState = useChantierState.getState();
    const notification = enqueueSnackbar(
      i18n.t('sync.notifications.save.inProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );

    interactionsState.enableReadOnlyMode(false);
    setIsSaving(true);
    try {
      if (useAppState.getState().hasEntitiesRelatedChangesToSave) {
        await saveDocumentToCloud({ notify: false, handleReadonlyMode: false });
      }

      if (
        useAppState.getState().hasCRMDossierRelatedChangesToSave ||
        useAppState.getState().hasCRMChantierRelatedChangesToSave
      ) {
        const extraParams: Omit<TSaveMetaDossier, 'retrieveParams'> = {};
        if (
          chantierState.chantierInternalReviewNotesMarkdown !==
          chantierState.remoteChantierInternalReviewNotesMarkdown
        ) {
          extraParams.chantierInternalReviewNotesMarkdown =
            chantierState.chantierInternalReviewNotesMarkdown ?? '';
        }

        await saveMetaDossier({
          retrieveParams: {
            mode: 'fromChantierId',
            chantierRowId: urlParams.chantierId!,
          },
          abricoChanges: interactionsState.metaDossierChanges,
          currentPhaseExtraDocTypes:
            chantierState.currentPhaseExtraDocTypes ?? [],
          reviews: {
            sendArtisanEmail: false,
            sendBenefEmail: false,
            forceSendAllToEmail: null,
            forDocs: [
              {
                dossierId: urlParams.dossierId!,
                rowId: interactionsState.metaDossierInfo!.review.docRowId,
                isValid:
                  interactionsState.reviewStatus === 'pending'
                    ? null
                    : interactionsState.reviewStatus === 'valid',
                reviewerName: useAppState.getState().user!.displayName,
                reviewFeedbackMarkdown:
                  interactionsState.reviewFeedbackMarkdown,
              },
            ],
          },
          ...extraParams,
        });
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['dossier', 'meta'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['chantier', 'meta'],
          }),
        ]);
        useAppState.getState().setHasCRMDossierRelatedChangesToSave(false);
        useAppState.getState().setHasCRMChantierRelatedChangesToSave(false);
        enqueueSnackbar(i18n.t('sync.notifications.save.success'), {
          variant: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(i18n.t('sync.notifications.save.failed'), {
        variant: 'error',
      });
    } finally {
      closeSnackbar(notification);
      interactionsState.disableReadOnlyMode();
      setIsSaving(false);
    }
  }, [urlParams.chantierId, urlParams.dossierId]);

  const { phaseDocs, otherPhaseDocs } = useChantierState(
    (state) => state.computedChantierDocs
  );
  const isInPhase = useMemo(() => {
    return phaseDocs.some((doc) => doc.dossierId === urlParams.dossierId);
  }, [phaseDocs, urlParams]);

  return (
    <Stack direction={'row'} gap={1}>
      <Button
        disabled={isInReadOnlyMode || !hasChangesToSave}
        onClick={saveEverything}
        loading={isSaving}
      >
        {t('ui.save')}
      </Button>
      {isInPhase ? (
        <IconButton
          variant={'solid'}
          color={'primary'}
          disabled={hasChangesToSave}
          onClick={goNextDoc}
        >
          <NavigateNextIcon />
        </IconButton>
      ) : (
        <>
          <IconButton
            disabled={isInReadOnlyMode || hasChangesToSave}
            onClick={() =>
              navigate(
                otherPhaseDocs.find(
                  (doc) => doc.dossierId === urlParams.dossierId
                )!.url + '/email'
              )
            }
            variant={'solid'}
            color={'primary'}
          >
            <EmailIcon />
          </IconButton>
        </>
      )}
    </Stack>
  );
}
