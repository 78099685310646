import { getBytes, ref, uploadBytes } from 'firebase/storage';

import { storage } from '../config/firebaseInit';

/**
 *  This function uploads a file to Cloud Storage and updates its metadata with the user's email.
 */
export const storeNewFileWithMetadata = async (
  file: File | Blob,
  filePath: string,
  metadata = {}
) => {
  try {
    // 1 - Upload the file to Cloud Storage with metadata
    const newFileRef = ref(storage, filePath);
    await uploadBytes(newFileRef, file, metadata);

    return newFileRef;
  } catch (error) {
    console.error(
      'There was an error uploading a file to Cloud Storage: ',
      error
    );
  }
};

export const getStoredFile = async (filePath: string): Promise<ArrayBuffer> => {
  const fileRef = ref(storage, filePath);
  return await getBytes(fileRef);
};

/**
 * This function saves files data as a file with metadata to Cloud Storage.
 */

export const saveFileDataWithMetadata = async (
  folderName: string,
  fileName: string,
  fileData: any,
  userEmail: string
) => {
  const arr = new Uint8Array(fileData);
  const blob = new Blob([arr], { type: 'application/pdf' });
  return storeNewFileWithMetadata(
    blob,
    `${folderName}/${fileName}`,
    userEmail
  ).then(() => console.log('updated.file.saved'));
};
