import { serverTimestamp } from '@firebase/firestore';
import { WebViewerInstance } from '@pdftron/webviewer';
import Bowser from 'bowser';
// eslint-disable-next-line import/named
import { User as FirebaseUser } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { db } from 'config/firebaseInit.ts';
import { queryClient } from 'queries';
import { getUserCompanies } from 'queries/company.ts';
import { getDossierById } from 'queries/dossiers.ts';
import { getCompanyTags } from 'queries/tags.ts';
import { ICompany, IDossier, ITag } from 'types/index';
import { getDossierPDFBytes } from 'utils/dossierHelpers.ts';

export type TUrlParams = {
  dossierId?: string;
  companyId?: string;
  chantierId?: string;
};

export interface AppState {
  urlParams: TUrlParams;
  setUrlParams: (params: TUrlParams) => void;
  newVersionAvailable: boolean;
  setNewVersionAvailable: (newVersionAvailable: boolean) => void;
  sessionId: string | null;
  setSessionId: (sessionId: string) => void;
  logDurationInFs: (params: {
    durationMs: number;
    name: string;
  }) => Promise<void>;
  hasChangesToSave: boolean;
  hasEntitiesRelatedChangesToSave: boolean;
  setHasEntitiesRelatedChangesToSave: (hasChanges: boolean) => void;
  hasCRMDossierRelatedChangesToSave: boolean;
  setHasCRMDossierRelatedChangesToSave: (hasChanges: boolean) => void;
  hasCRMChantierRelatedChangesToSave: boolean;
  setHasCRMChantierRelatedChangesToSave: (hasChanges: boolean) => void;
  navigate: (path: string, bypassUnsaved?: boolean) => boolean;
  internalNavigate: (path: string) => void;
  setNavigate: (navigate: (path: string) => void) => void;
  navigateNext: null | string;
  resetNavigateNext: () => void;
  instance: WebViewerInstance | null;
  setInstance: (instance: WebViewerInstance) => void;
  getInstance: () => Promise<WebViewerInstance>;
  multiViewerEnabled: boolean;
  setMultiViewerEnabled: (enabled: boolean) => void;
  getDossierId: () => string;
  user: FirebaseUser | null;
  setUser: (user: FirebaseUser | null) => void;
  getUser: () => Promise<FirebaseUser>;
  userCompanies: ICompany[];
  _setUserCompanies: (companies: ICompany[]) => void;
  company: ICompany | null;
  setCompany: (company: ICompany | null) => void;
  getCompany: () => Promise<ICompany>;
  tags: ITag[];
  refreshCompanyTags: () => Promise<void>;
  viewedPdfIsLoadingData: boolean;
  viewedPdfData: Uint8Array | null;
  downloadViewedPdf: (dossier: IDossier) => Promise<Uint8Array>;
  refDossierStartMs: number;
}

export const toto = {};

export const useAppState = create<AppState>()(
  devtools(
    (set, get, store) => ({
      urlParams: {} as TUrlParams,
      setUrlParams: (params: TUrlParams) => {
        set({ urlParams: params });
      },
      newVersionAvailable: false as boolean,
      setNewVersionAvailable: (newVersionAvailable: boolean) => {
        set({ newVersionAvailable });
      },
      sessionId: null as string | null,
      setSessionId: (sessionId: string) => {
        set({ sessionId });
      },
      logDurationInFs: async ({
        durationMs,
        name,
      }: {
        durationMs: number;
        name: string;
      }) => {
        const extraInfo: {
          dossierId?: string;
          userEmail?: string;
          companyId?: string;
        } = {};
        const urlParams = get().urlParams;
        if (urlParams.dossierId) {
          extraInfo.dossierId = urlParams.dossierId;
        }
        if (get().user?.email) {
          extraInfo.userEmail = get().user!.email!;
        }
        if (get().company?.id) {
          extraInfo.companyId = get().company!.id!;
        }

        const payload = {
          origin: 'station',
          name,
          durationMs,
          ...extraInfo,
        };
        await addDoc(collection(db, 'timings'), {
          ...payload,
          browser: Bowser.getParser(window.navigator.userAgent).getResult(),
          createdAt: serverTimestamp(),
        });
        console.debug('Logged timing:', payload);
      },
      hasChangesToSave: false as boolean,
      hasEntitiesRelatedChangesToSave: false as boolean,
      setHasEntitiesRelatedChangesToSave: (hasChanges: boolean) => {
        set(() => ({
          hasEntitiesRelatedChangesToSave: hasChanges,
          hasChangesToSave:
            hasChanges ||
            get().hasCRMDossierRelatedChangesToSave ||
            get().hasCRMChantierRelatedChangesToSave,
        }));
      },
      hasCRMDossierRelatedChangesToSave: false as boolean,
      setHasCRMDossierRelatedChangesToSave: (hasChanges: boolean) => {
        set(() => ({
          hasCRMDossierRelatedChangesToSave: hasChanges,
          hasChangesToSave:
            hasChanges ||
            get().hasEntitiesRelatedChangesToSave ||
            get().hasCRMChantierRelatedChangesToSave,
        }));
      },
      hasCRMChantierRelatedChangesToSave: false as boolean,
      setHasCRMChantierRelatedChangesToSave: (hasChanges: boolean) => {
        set(() => ({
          hasCRMChantierRelatedChangesToSave: hasChanges,
          hasChangesToSave:
            hasChanges ||
            get().hasEntitiesRelatedChangesToSave ||
            get().hasCRMDossierRelatedChangesToSave,
        }));
      },
      navigate: (path: string, bypassUnsaved?: boolean) => {
        if (get().hasChangesToSave && !bypassUnsaved) {
          set({ navigateNext: path });
          return false;
        } else {
          set({
            navigateNext: null,
            hasEntitiesRelatedChangesToSave: false,
            hasCRMDossierRelatedChangesToSave: false,
            hasCRMChantierRelatedChangesToSave: false,
            hasChangesToSave: false,
          });
          get().internalNavigate(path);
          return true;
        }
      },
      navigateNext: null as string | null,
      resetNavigateNext: () => {
        set({ navigateNext: null });
      },
      internalNavigate: (path: string) => {
        console.error(
          `No navigate function set, cannot navigate to path ${path}`
        );
      },
      setNavigate: (navigate: (path: string) => void) => {
        set({ internalNavigate: navigate });
      },
      instance: null as WebViewerInstance | null,
      setInstance: (instance: WebViewerInstance) => {
        set(() => ({ instance }));
      },
      multiViewerEnabled: false as boolean,
      setMultiViewerEnabled: (enabled: boolean) => {
        set(() => ({ multiViewerEnabled: enabled }));
      },
      getInstance: async () => {
        if (get().instance) {
          return get().instance!;
        }
        return new Promise((resolve) => {
          const unsubscribe = store.subscribe((state) => {
            if (state.instance) {
              unsubscribe();
              resolve(state.instance! as WebViewerInstance);
            }
          });
        });
      },
      getDossierId: () => {
        const urlParams = get().urlParams;
        if (urlParams.dossierId) {
          return urlParams.dossierId;
        } else {
          throw new Error('No dossierId in urlParams');
        }
      },
      user: null as FirebaseUser | null,
      setUser: (user: FirebaseUser | null) => {
        set({ user });
      },
      getUser: async () => {
        if (get().user) {
          return get().user!;
        }
        return new Promise((resolve) => {
          const unsubscribe = store.subscribe((state) => {
            if (state.user) {
              unsubscribe();
              resolve(state.user!);
            }
          });
        });
      },
      company: null as ICompany | null,
      userCompanies: [] as ICompany[],
      _setUserCompanies: (companies: ICompany[]) => {
        set({ userCompanies: companies });
      },
      setCompany: (company: ICompany | null) => {
        set({
          company,
          tags: get().company?.id === company?.id ? get().tags : [],
        });
      },
      getCompany: async () => {
        if (get().company) {
          return get().company!;
        }
        return new Promise((resolve) => {
          const unsubscribe = store.subscribe((state) => {
            if (state.company) {
              unsubscribe();
              resolve(state.company!);
            }
          });
        });
      },
      tags: [] as ITag[],
      refreshCompanyTags: async () => {
        const company = get().company;
        if (company) {
          const tags = await getCompanyTags(company.id);
          set({ tags });
        }
      },
      viewedPdfIsLoadingData: false as boolean,
      viewedPdfData: null as Uint8Array | null,
      downloadViewedPdf: async (dossier: IDossier) => {
        // This doesn't support multiple dossier downloads at the same time
        // Will need to be updated if we want to support that
        if (get().viewedPdfData) {
          return get().viewedPdfData as Uint8Array;
        }
        if (get().viewedPdfIsLoadingData) {
          return new Promise((resolve) => {
            const unsubscribe = store.subscribe((state) => {
              if (!state.viewedPdfIsLoadingData) {
                unsubscribe();
                resolve(get().viewedPdfData as Uint8Array);
              }
            });
          });
        }

        set({ viewedPdfIsLoadingData: true });

        const pdfArrayBuffer = await getDossierPDFBytes(dossier);
        get().logDurationInFs({
          durationMs: performance.now() - get().refDossierStartMs,
          name: 'Donwload PDF',
        });

        set({
          viewedPdfData: new Uint8Array(pdfArrayBuffer),
          viewedPdfIsLoadingData: false,
        });
        return get().viewedPdfData as Uint8Array;
      },
      refDossierStartMs: performance.now(),
    }),
    {
      name: 'app-store',
    }
  )
);

useAppState.subscribe(async (state, prevState) => {
  if (state.user?.uid !== prevState.user?.uid) {
    if (state.user?.uid) {
      const companies = await getUserCompanies(state.user.uid);
      useAppState.getState()._setUserCompanies(companies);
    }
  }

  if (state.company?.id !== prevState.company?.id) {
    await useAppState.getState().refreshCompanyTags();
  }

  if (
    (state.urlParams?.companyId !== prevState.urlParams?.companyId &&
      state.urlParams?.companyId) ||
    state.userCompanies !== prevState.userCompanies
  ) {
    if (state.userCompanies && state.urlParams?.companyId) {
      const company = state.userCompanies.find(
        (c) => c.id === state.urlParams.companyId
      );
      if (company) {
        useAppState.getState().setCompany(company);
      } else {
        useAppState.getState().setCompany(null);
      }
    } else {
      if (state.userCompanies.length && !state.urlParams.companyId) {
        useAppState.getState().setCompany(state.userCompanies[0]);
      } else {
        useAppState.getState().setCompany(null);
      }
    }
  }

  if (state.urlParams?.dossierId !== prevState.urlParams?.dossierId) {
    useAppState.setState({ refDossierStartMs: performance.now() });

    queryClient.invalidateQueries({
      queryKey: ['dossier'],
    });

    const instance = useAppState.getState().instance;
    if (instance) {
      instance.UI.closeDocument();
    }

    const dossierId = state.urlParams?.dossierId;
    if (dossierId) {
      const dossier = (await getDossierById(dossierId))!;
      useAppState.setState({ viewedPdfData: null });
      useAppState.getState().downloadViewedPdf(dossier);
    } else {
      const latestHtmlPageTxt = await fetch(window.location.href, {
        method: 'GET',
        headers: { 'cache-control': 'no-cache', pragma: 'no-cache' },
      }).then((response) => response.text());
      const versionRegexp =
        /<script type="module" .* src="\/assets\/index-(.*)\.js"/;
      const latestVersion = latestHtmlPageTxt.match(versionRegexp)?.[1];
      const currentVersion =
        document.documentElement.innerHTML.match(versionRegexp)?.[1];

      if (latestVersion && currentVersion && latestVersion !== currentVersion) {
        useAppState.getState().setNewVersionAvailable(true);
      }
    }
  }
});
