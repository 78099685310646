import { Tooltip } from '@mui/joy';
import Chip from '@mui/joy/Chip';

import { useChantierState } from 'stores/chantierStore.ts';
import { labelByGlideDocTypesRowId } from 'utils/constants.ts';

export default function DossierStatusChip() {
  const remoteCurrentPhaseMissingDocTypes = useChantierState(
    (state) => state.remoteCurrentPhaseMissingDocTypes
  );

  if (
    remoteCurrentPhaseMissingDocTypes &&
    remoteCurrentPhaseMissingDocTypes.length > 0
  ) {
    return (
      <Tooltip
        title={`Docs manquants : ${remoteCurrentPhaseMissingDocTypes.map((el) => labelByGlideDocTypesRowId[el]).join(', ')}`}
      >
        <Chip color="danger" variant="solid">
          Incomplet
        </Chip>
      </Tooltip>
    );
  }
  return <></>;
}
