import Add from '@mui/icons-material/Add';
import { Badge, DialogActions, Stack, Tooltip } from '@mui/joy';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import IconButton from '@mui/joy/IconButton';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';

import { glideDocTypesRawTable } from 'generated/glideDocTypes.ts';
import { useChantierState } from 'stores/chantierStore.ts';

type Props = {
  onClose?: () => void;
  variant: 'iconButton' | 'button';
};

const OPTIONS = [...glideDocTypesRawTable].sort((a, b) =>
  a.label.localeCompare(b.label)
);

export default function SelectPhaseExtrasDocs({ onClose, variant }: Props) {
  const [open, setOpen] = useState(false);
  const currentPhaseExtraDocTypes = useChantierState(
    (state) => state.currentPhaseExtraDocTypes
  );
  const setCurrentPhaseExtraDocTypes = useChantierState(
    (state) => state.setCurrentPhaseExtraDocTypes
  );

  return (
    <>
      <Tooltip
        title={'Éditer les docs extras nécessaire pour valider la phase'}
      >
        <Badge
          color={'warning'}
          badgeContent={(currentPhaseExtraDocTypes ?? []).length}
        >
          {variant === 'button' ? (
            <Button
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
              size={'sm'}
              variant={'outlined'}
            >
              <Typography level={'body-sm'}>Extra docs</Typography>
            </Button>
          ) : (
            <IconButton
              onClick={() => setOpen(true)}
              size={'sm'}
              variant={'outlined'}
            >
              <Add />
            </IconButton>
          )}
        </Badge>
      </Tooltip>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        keepMounted={false}
      >
        <ModalDialog>
          <DialogTitle>Documents supplémentaires</DialogTitle>
          <DialogContent>
            <Stack direction={'column'} gap={1}>
              <Typography>
                Pour quand vous avez besoin d&apos;un doc supplémentaire, hors
                de ce qui est prévu par défaut.
              </Typography>
              <Stack direction={'row'}>
                <Select
                  value={currentPhaseExtraDocTypes ?? []}
                  placeholder={'Aucuns docs supplémentaires'}
                  multiple
                  renderValue={(selected) => (
                    <Stack direction={'column'} gap={0.5}>
                      {selected.map((selectedOption) => (
                        <Chip
                          key={selectedOption.value}
                          variant="soft"
                          color="primary"
                        >
                          {selectedOption.label}
                        </Chip>
                      ))}
                    </Stack>
                  )}
                  onChange={(_, value) => setCurrentPhaseExtraDocTypes(value)}
                  slotProps={{
                    listbox: {
                      sx: {
                        width: '100%',
                      },
                    },
                  }}
                >
                  {OPTIONS.map((docType) => (
                    <Option key={docType.rowId} value={docType.rowId}>
                      {docType.label}
                    </Option>
                  ))}
                </Select>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={'row'} gap={1}>
              <Button onClick={() => setOpen(false)}>Ok</Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
