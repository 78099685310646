import { getStoredFile } from 'queries/storage.ts';
import { IDossier } from 'types/index';

export function getDossierGcsFileName(
  dossierId: string,
  pdfFileVersion?: number,
  options: { withExtension: boolean } = { withExtension: true }
): string {
  const suffix = pdfFileVersion ? `+${pdfFileVersion}` : '';
  return `${dossierId}-current${suffix}${options.withExtension ? '.pdf' : ''}`;
}

export function getDossierInputFolderPath(
  companyId: string,
  dossierId: string
) {
  return `${companyId}/${dossierId}/files`;
}

export async function getDossierPDFBytes(
  dossier: IDossier
): Promise<ArrayBuffer> {
  return await getStoredFile(
    [
      getDossierInputFolderPath(dossier.companyId, dossier.id),
      getDossierGcsFileName(dossier.id, dossier.pdfFileVersion),
    ].join('/')
  );
}
