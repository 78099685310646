import { Tooltip } from '@mui/joy';
import Box from '@mui/joy/Box';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { infoByAnnotStatus } from 'components/Entity/annotStatusConstants.tsx';
import { useUiState } from 'stores/uiStore.ts';
import { AnnotStatus } from 'types/index';

const ValidationButtons = ({
  status,
  setStatus,
  kind,
}: {
  status: AnnotStatus;
  setStatus: (status: AnnotStatus) => void;
  kind: 'extracted' | 'postProcessed' | 'missing';
}) => {
  const { t } = useTranslation();
  const documentFullyLoaded = useUiState((state) => state.documentFullyLoaded);

  return (
    <RadioGroup
      orientation="horizontal"
      name="alignment"
      variant="outlined"
      value={status}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newStatus =
          AnnotStatus[event.target.value as keyof typeof AnnotStatus];
        setStatus(newStatus);
      }}
      sx={{ filter: documentFullyLoaded ? 'unset' : 'blur(4px)' }}
    >
      {[AnnotStatus.REJECTED, AnnotStatus.PENDING, AnnotStatus.VALIDATED].map(
        (statusOption) => (
          <Tooltip
            key={statusOption}
            title={t(`validation.editStatusTooltips.${kind}.${statusOption}`)}
            variant="soft"
            placement={'top'}
            color={'neutral'}
            disableHoverListener={!documentFullyLoaded}
            sx={{ p: 1, pr: 2, pl: 2 }}
          >
            <Box
              sx={(theme) => ({
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 28,
                height: 28,
                '&:not([data-first-child])': {
                  borderLeft: '1px solid',
                  borderColor: 'divider',
                },
                [`&[data-first-child] .${radioClasses.action}`]: {
                  borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                  borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                },
                [`&[data-last-child] .${radioClasses.action}`]: {
                  borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                  borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                },
              })}
            >
              <Radio
                value={statusOption}
                disableIcon
                overlay
                label={infoByAnnotStatus[statusOption].icon[kind]}
                variant={status === statusOption ? 'solid' : 'plain'}
                disabled={!documentFullyLoaded}
                color={
                  status === statusOption
                    ? infoByAnnotStatus[statusOption].muiColor
                    : 'neutral'
                }
                slotProps={{
                  action: {
                    sx: { borderRadius: 0, transition: 'none' },
                  },
                  label: { sx: { lineHeight: 0 } },
                }}
              />
            </Box>
          </Tooltip>
        )
      )}
    </RadioGroup>
  );
};

const EntityStatusButtons = memo(ValidationButtons);
export default EntityStatusButtons;
