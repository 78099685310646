import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

type TUseEffectParams = Parameters<typeof useEffect>;

/**
 * Custom hook that allows to use useEffect with deep comparison of dependencies
 * And also optionnaly skip the first render
 *
 * Inspired by: https://marcoghiani.com/blog/how-to-use-the-react-hook-usedeepeffect
 */
export function useDeepEffect(
  effectFunc: TUseEffectParams[0],
  deps: TUseEffectParams[1],
  skipFirstRender: boolean = false
) {
  // 1° Step
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    // 2° Step
    const isSame = prevDeps.current!.every((obj, index) =>
      isEqual(obj, deps![index])
    );

    // 3° Step
    if ((!skipFirstRender && isFirst.current) || !isSame) {
      effectFunc();
    }
    // 4° Step
    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
}
