// Helper function to split the string into a set of words
function getWords(str: string): Set<string> {
  return new Set(str.toLowerCase().split(/\s+/));
}

export function findMostCommonWords(
  a: string,
  options: string[]
): string | null {
  const aWords = getWords(a);
  let maxCommonCount = 0;
  let bestOption: string | null = null;
  let isTied = false;

  for (const option of options) {
    const optionWords = getWords(option);
    const commonWordsCount = [...optionWords].filter((word) =>
      aWords.has(word)
    ).length;

    if (commonWordsCount > maxCommonCount) {
      maxCommonCount = commonWordsCount;
      bestOption = option;
      isTied = false; // Reset the tie flag since we found a better option
    } else if (commonWordsCount === maxCommonCount && maxCommonCount > 0) {
      isTied = true; // Set the tie flag if there's another option with the same count
    }
  }

  // If there's a tie, return null, otherwise return the best option
  return isTied ? null : bestOption;
}

export function capitalizeFirstLetterOfEachWord(str: string): string {
  return str
    .split(' ') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a string
}
