import { queryOptions } from '@tanstack/react-query';

import { getChantierMetaDossier } from 'cloudFunctions/functions.ts';
import { useAppState } from 'stores/appStore.ts';
import { useChantierState } from 'stores/chantierStore.ts';
import { TMetaDossierBaseForChantier } from 'types/index';

export const getChantierInfo = () => {
  const chantierId = useAppState.getState().urlParams.chantierId;
  if (!chantierId) {
    throw new Error('chantierId is not defined');
  }

  const queryFunction =
    async (): Promise<TMetaDossierBaseForChantier | null> => {
      try {
        useChantierState.getState().setMetaDossierForChantierIsLoading(true);
        const { data } = await getChantierMetaDossier({
          mode: 'fromChantierId',
          chantierRowId: chantierId,
        });
        if (data) {
          useChantierState.getState().setMetaDossierForChantier(data);
        }
        return data;
      } catch (error) {
        console.error('Error reloading metaDossier', error);
        return null;
      } finally {
        useChantierState.getState().setMetaDossierForChantierIsLoading(false);
      }
    };

  return queryOptions({
    queryKey: ['chantier', 'meta'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};
