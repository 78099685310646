import Bowser from 'bowser';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import firebaseConfig from 'config/firebaseConfig.ts';
import { db } from 'config/firebaseInit';
import { useAppState } from 'stores/appStore.ts';

export const createDossierSession = async () => {
  const dossierId = useAppState.getState().getDossierId();
  const companyId = (await useAppState.getState().getCompany()).id;
  const userEmail = (await useAppState.getState().getUser()).email!;

  const dossierSessionsRef = collection(db, 'dossierSessions');
  return await addDoc(dossierSessionsRef, {
    dossierId,
    userEmail,
    startTime: serverTimestamp(),
    companyId,
    browser: Bowser.getParser(window.navigator.userAgent).getResult(),
  });
};

export const closeDossierSession = ({
  sessionId,
  dossierId,
  releaseRwLock,
}: {
  sessionId: string | null;
  dossierId: string;
  releaseRwLock: boolean;
}) => {
  if (sessionId === null) {
    return;
  }

  // We handle the closing of the session through navigator.sendBeacon and a remote CF
  // all other methods (doing the FS modifications client side) have proven to be unreliable, unfortunately.
  const body = {
    dossierId,
    sessionId,
    releaseRwLock,
  };

  const url = `https://europe-west9-${firebaseConfig.projectId}.cloudfunctions.net/stationHelpers-closeSession`;

  navigator.sendBeacon(
    url,
    // We send the data as plain text to avoid CORS issues https://stackoverflow.com/a/44142982
    JSON.stringify(body)
  );
};
