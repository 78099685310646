import { Card, Stack } from '@mui/joy';

import { InternalReviewNote } from 'components/Review/InternalReviewNote.tsx';
import { MasterNavigation } from 'components/UI/MasterNavigation.tsx';

export function ReviewNavBar() {
  return (
    <Card sx={{ borderRadius: 12, borderWidth: 4, p: 1 }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
        gap={1}
      >
        <InternalReviewNote />
        <MasterNavigation />
      </Stack>
    </Card>
  );
}
