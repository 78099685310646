import {
  BoldItalicUnderlineToggles,
  frontmatterPlugin,
  headingsPlugin,
  listsPlugin,
  MDXEditor,
  quotePlugin,
  toolbarPlugin,
  UndoRedo,
  Separator,
  ListsToggle,
  markdownShortcutPlugin,
  // eslint-disable-next-line import/named
  MDXEditorMethods,
  Button,
} from '@mdxeditor/editor';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircularProgress from '@mui/joy/CircularProgress';
import { useCallback, useEffect, useRef, useState } from 'react';

import { sleep } from 'utils/promise.ts';

export function MarkdownEditor({
  readOnly,
  value,
  onChange = () => {},
  placeholder = 'Write something...',
  generateAi = undefined,
}: {
  readOnly: boolean;
  value: string | null;
  onChange?: (markdown: string | null) => void;
  placeholder?: string;
  generateAi?: () => Promise<string>;
}) {
  const ref = useRef<MDXEditorMethods>(null);
  const [isGeneratingAi, setIsGeneratingAi] = useState(false);

  const handleGenerateAi = useCallback(async () => {
    setIsGeneratingAi(true);
    try {
      // We reset the markdown to make sure we can later check that something had been generated
      ref.current?.setMarkdown('');
      while (ref.current?.getMarkdown() !== '') {
        console.log('waiting for markdown to be actually set');
        await sleep(50);
      }

      const res = (await generateAi!()).trim();
      if (res) {
        ref.current?.setMarkdown(res);

        // We wait to make sure the markdown is actually set / transformed
        // for some reason this might take a bit of time
        while (ref.current?.getMarkdown() === '') {
          console.log('waiting for markdown to be actually set');
          await sleep(50);
        }

        // We return the markdown from the component as it's doing some transformation
        // which might cause unwanted diffs
        onChange(ref.current?.getMarkdown() ?? '');
      }
    } finally {
      setIsGeneratingAi(false);
    }
  }, [generateAi, onChange]);

  useEffect(() => {
    if (ref.current?.getMarkdown() !== value) {
      ref.current?.setMarkdown(value ?? '');
    }
  }, [value]);

  return (
    <div style={{ overflowY: 'auto' }}>
      <MDXEditor
        ref={ref}
        className="dark-theme dark-editor"
        markdown={value || ''}
        readOnly={readOnly || isGeneratingAi}
        onChange={onChange}
        placeholder={placeholder}
        plugins={[
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <Separator />
                <BoldItalicUnderlineToggles />
                <Separator />
                {/*<BlockTypeSelect />*/}
                <ListsToggle />
                {generateAi && (
                  <>
                    <Separator />
                    <Button
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                      disabled={isGeneratingAi}
                      onClick={handleGenerateAi}
                    >
                      Ai{' '}
                      <AutoAwesomeIcon
                        color={'action'}
                        fontSize={'small'}
                        sx={{ marginLeft: 0.5 }}
                      />
                      {isGeneratingAi && <CircularProgress size={'sm'} />}
                    </Button>
                  </>
                )}
              </>
            ),
          }),
          listsPlugin(),
          quotePlugin(),
          headingsPlugin(),
          frontmatterPlugin(),
          markdownShortcutPlugin(),
        ]}
      />
    </div>
  );
}
