import { FieldValue } from '@firebase/firestore';
import { z } from 'zod';

import { TDynamicCondition } from 'types/dynamicExpressions.ts';
import { ABRICO_DOCUMENT_ROW_IDS } from 'utils/constants';

export enum WorkflowStatus {
  COMPLETED = 'completed',
  STARTED = 'started',
  FAILED = 'failed',
}

export type Order = 'asc' | 'desc';

export type TranslationT = (
  a: string | string[],
  b?: Record<string, unknown>
) => string;

export type TDynamicRules =
  | string
  | ({ precondition?: TDynamicCondition } & (
      | { label: string }
      | { labels: string[] }
    ));

export type TDynamicRulesWithCheck =
  | string
  | ({ precondition?: TDynamicCondition; check: TDynamicCondition } & (
      | { label: string }
      | { labels: string[] }
    ));

export interface IEntity {
  confidence: number;
  mentionText: string;
  boundingBox: { x: number; y: number }[];
  pageNumber: number;
  type: string;
  docType?: IDocType;
  docKey?: string;
  propertyType?: string;
  normalizedValue?: string;
  propertiesLength?: number;
  groupKey?: number;
  parentProperty?: string;
  dynamicRules: TDynamicRules[];
}

export interface IEntityForDisplay extends IEntity {
  id: string;
  comparisonId: string;
  transformedText: string;
  label: string;
  kind: 'extracted';
}

export interface IPostProcessedEntity {
  id: string;
  comparisonId: string;
  transformedText: string;
  // Ease common API with IEntity
  mentionText: string;
  type: string;
  docType: IDocType;
  docKey: string;
  label: string;
  kind: 'postProcessed';
  groupKey?: number;
  parentProperty?: string;
  dynamicRules: TDynamicRules[];
}

export interface IMissingEntity {
  id: string;
  comparisonId: string;
  type: string;
  docType: IDocType;
  docKey: string;
  label: string;
  kind: 'missing';
  dynamicRules?: TDynamicRules[];
}

export type TFakeMissingEntityGroup = {
  // For a smooth data handling, we put missing fields as a separate 'fake group'
  fakeGroup: true;
  entities: [];
  missingEntities: IMissingEntity[];
};

export type TFakeEntityGroup = {
  // For a smooth data handling, we put simple entities as a separate 'fake group'
  fakeGroup: true;
  entities: [IEntityForDisplay | IPostProcessedEntity];
  missingEntities: [];
};

export type TEntityGroup = {
  // This is a real group of entities
  fakeGroup: false;
  label: string;
  entities: (IEntityForDisplay | IPostProcessedEntity)[];
  missingEntities: IMissingEntity[];
};

export interface IPreparedDocSection {
  sectionName: string;
  missingEntities: IMissingEntity[];
  groups: (TFakeEntityGroup | TEntityGroup)[];
}

export interface IPreparedDocEntities {
  docKey: string;
  docType: IDocType;
  preparedSection: IPreparedDocSection[];
}

export type IPreparedEntities = {
  docType: IDocType;
  entitiesForDoc: IPreparedDocEntities[];
}[];

export interface IDossier extends DossierDataAtCreation {
  id: string;
  docTypesByFileByPage?: IDocTypesByFileByPage;
  size?: number;
  updatedAt?: FieldValue;
  workflowStatus?: WorkflowStatus;
  workflowExecutions: number;
  disableAutoReorder?: boolean;
  displayedPageNumberByWorkflowPageNumber?: Record<string, number>;
  pdfFileVersion?: number;
  metadataIsFromWorkflow?: boolean;
  archived?: boolean | null;
  archivedAt?: FieldValue | null;
  archivedBy?: string | null;
  codesAdeme: string[];
  glideMetaData?: {
    extraData?: {
      chantierId: string;
    };
  };
}

export interface IDossierWithAnalysis extends IDossier {
  analysis: TAnalysisResponse;
}

export enum DossierStatus {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  VALID = 'valid',
  REJECTED = 'rejected',
  INVALID = 'invalid',
  INCOMPLETE = 'incomplete',
}

export interface DossierDataAtCreation {
  name: string;
  companyId: string;
  createdBy: string;
  createdAt: FieldValue;
  isUniqueDevis: boolean;
  tagId: string;
  status: DossierStatus;
  codesAdeme: string[];
}

export interface IDossierLock {
  dossierId: string;
  kickHistory?: {
    email: string;
    uid: string;
    rwOwnershipStartedAt: FieldValue;
  }[];
  owner?: { email: string; uid: string };
  viewId?: string;
  createdAt?: FieldValue;
}

export interface IFileInfo {
  name: string;
  url: string;
  storageUri: string;
  size: number;
  timeCreated: string;
  updated?: string;
  uploadedBy: string;
}

export enum IDocType {
  DEVIS = 'devis',
  DEVIS_NON_SIGNE = 'devis_ns',
  FACTURE = 'facture',
  AVIS_IMPOSITION = 'avis_imposition',
  AUTRE = 'autre',
  ATTESTATION_RGE = 'attestation_rge',
  ATTESTATION_SUR_HONNEUR = 'attestation_sur_honneur',
  CADRE_CONTRIBUTION = 'cadre_contribution',
  CNI = 'CNI',
  NOTE_DIMENSIONNEMENT = 'note_dimensionnement',
}

export type IDocTypesByFileByPage = {
  [K in IDocType]?: {
    [fileName: string]: number[];
  };
};

export const CompanyZod = z.object({
  id: z.string(),
  name: z.string(),
  members: z.array(z.string()).default([]),
  admin: z.array(z.string()).default([]),
  featureFlags: z
    .object({
      handleMissingFields: z.boolean().default(false),
      handleFieldRules: z.boolean().default(false),
      handleBackofficeSync: z.boolean().default(false),
    })
    .default({}),
  logo: z
    .object({
      url: z.string(),
      storageUri: z.string().optional(),
    })
    .optional(),
});

export type ICompany = z.infer<typeof CompanyZod>;

export enum TagRole {
  DEFAULT = 'default',
}

export interface ITag {
  id: string;
  name: string;
  companyId: string;
  role?: TagRole;
  hideForUser?: string[];
}

export type ISeverity = 'success' | 'error' | 'info' | 'warning';

export type IError = {
  severity: ISeverity;
  code: string;
};

export type IVerificationSignatureResult = {
  signed: boolean;
  signer: string;
  signerName: string;
  signTime: any;
  verificationStatus: number;
  documentStatus: number;
  digestStatus: number;
  trustStatus: number;
  permissionStatus: number;
  disallowedChanges: { objnum: number; type: string }[];
  trustVerificationResultBoolean: boolean;
  trustVerificationResultString: string;
  timeOfTrustVerificationEnum: number;
  trustVerificationTime: any;
  id: number;
  badgeIcon: string;
  validSignerIdentity: boolean;
  digestAlgorithm: string;
  documentPermission: number;
  isCertification: boolean;
  contactInfo: string;
  location: string;
  reason: string;
  issuerField: string;
  subjectField: string;
  validAtTimeOfSigning: boolean;
  formatedSignTime: Date;
};

export enum AnnotStatus {
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type TEntityVersionInfo =
  | {
      kind: 'extracted' | 'postProcessed';
      transformedText: string;
      normalizedTransformedText: string;
      status: AnnotStatus;
      rejectedReason?: string;
    }
  | {
      kind: 'missing';
      status: AnnotStatus;
      rejectedReason?: string;
    };

export type TInteractionsFS = {
  dossierId?: string;
  entitiesStatuses: Record<string, AnnotStatus>;
  entitiesRejectionReasons?: Record<string, string>;
  prevVersion?: {
    dossierId: string;
    // string is the comparisonId here
    data: Record<string, TEntityVersionInfo>;
  };
};

export type TGroupDef = { fields: string[]; label: string };

export type TSectionsRes = {
  [K in IDocType]?: {
    readonly label: string;
    readonly fields: (string | TGroupDef)[];
  }[];
};

export type TMissingFieldRes = {
  kind: 'missing';
  docType: string;
  docKey: string;
  type: string;
  label: string;
  dynamicRules: TDynamicRules[];
  comparisonId: string;
  id: string;
};

export type TMissingFieldsRes = {
  [k in IDocType]?: Record<string, TMissingFieldRes[]>;
};

export type TAnalysisResponse = {
  sections: TSectionsRes;
  missingFields: TMissingFieldsRes;
  extractedEntities: IEntityForDisplay[];
  postProcessedEntities: IPostProcessedEntity[];
  // Information that will not need (async) post processing
  baseMetaDossierInfo?: TBaseMetaDossierInfo;
};

// We might support other "string" types in the future.
export type TSyncableTypes =
  | 'string'
  | 'number'
  | 'boolean'
  | 'date-time'
  | 'email-address';

export type TOpBaseInfo = { rowId: string; codeAdeme: string };

export type TBaseMetaDossierInfo = {
  name: string;
  glideDocTypeRowId: string;
  // FIXME add name
  docOperations: TOpBaseInfo[];
  // FIXME add name
  chantierOperations: TOpBaseInfo[];
};

export type TSyncValue = string | number | boolean | null;

export type TMetaDossierEntry = {
  metaKey: string;
  value: TSyncValue;
  label: string;
  type: TSyncableTypes;
  internalPath: string;
  isReadOnlyStation: boolean;
  dynamicRules: TDynamicRulesWithCheck[];
  stringOptions?: readonly [string, ...string[]];
};

export type TMetaDossierData = {
  chantier: Record<string, TMetaDossierEntry>;
  benef: Record<string, TMetaDossierEntry>;
  company: Record<string, TMetaDossierEntry>;
  operations: Record<
    string,
    { codeAdeme: string; data: Record<string, TMetaDossierEntry> }
  >;
};

export type TMetaDossierByAnnotIdValue = {
  metaDossierDataPath: string;
  annotId: string;
  computedIsEqual: boolean;
  _original: {
    originalMetaDossierValue: TSyncValue;
    originalAnnotTransformedText: string;
  };
};

export type TRemoteReviewState = {
  docRowId: string;
  reviewed: boolean | null;
  isValid: boolean | null;
  reviewFeedbackMarkdown: string | null;
};

export type TMetaDossierResponse = {
  baseMetaDossierInfo: TBaseMetaDossierInfo;
  metaDossierData: TMetaDossierData;
  metaDossierLinkByAnnotId: Record<string, TMetaDossierByAnnotIdValue[]>;
  review: TRemoteReviewState;
  chantierInternalReviewNotesMarkdown: string | null;
};

export type TWipMetaEl = {
  metaPath: string;
  hasBeenEdited: boolean;
  type: TSyncableTypes;
  value: TSyncValue;
  label: string;
  remoteValue: TSyncValue;
  errors: string[];
  dynamicRules: TDynamicRulesWithCheck[];
  isReadOnlyStation: boolean;
  stringOptions?: readonly string[];
};

export type TMetaDossierWipElByMetaPath = Map<string, TWipMetaEl>;

export type TReviewInfo = {
  stats: { [k in AnnotStatus]: number };
  rejectedEntities: {
    id: string;
    label: string;
    status: AnnotStatus;
    rejectionReason: string;
    kind: 'postProcessed' | 'missing' | 'extracted';
  }[];
};

export const ZGenerateReviewFeedbackBody = z.object({
  dossierId: z.string(),
  rejections: z.array(
    z.object({
      label: z.string(),
      rejectionReason: z.string(),
      isMissing: z.boolean().default(false),
    })
  ),
});

export const REVIEW_PHASES_ORDERED = [
  'debut_chantier',
  'firstdepot_ke',
  'attente_validation_financement',
  'fin_chantier',
  'lastdepot_ke',
] as const;
export type TDossierPhase = (typeof REVIEW_PHASES_ORDERED)[number];

export const ZSaveMetaDossierReviews = z.object({
  sendBenefEmail: z.boolean(),
  sendArtisanEmail: z.boolean(),
  forceSendAllToEmail: z.string().email().nullable(),
  forDocs: z
    .array(
      z.object({
        dossierId: z.string(),
        rowId: z.string(),
        isValid: z.boolean().nullable(),
        reviewFeedbackMarkdown: z.string().nullable(),
        reviewerName: z.string().nullable(),
      })
    )
    .min(1),
});
export type TSaveMetaDossierReviews = z.infer<typeof ZSaveMetaDossierReviews>;

export const ZSaveMetaDossier = z.object({
  retrieveParams: z.union([
    z.object({
      mode: z.literal('fromDossierId'),
      dossierId: z.string(),
    }),
    z.object({
      mode: z.literal('fromBenefRowId'),
      benefRowId: z.string(),
    }),
    z.object({
      mode: z.literal('fromChantierId'),
      chantierRowId: z.string(),
    }),
  ]),
  // changes are a {k: v} record, where k is the "." path to the field in the simplified metaDossierData (TMetaDossierData)
  abricoChanges: z
    .record(z.union([z.string(), z.number(), z.boolean(), z.null()]))
    .optional(),
  onboardingStatus: z.string().optional(),
  chantierInternalReviewNotesMarkdown: z.string().optional(),
  reviews: ZSaveMetaDossierReviews.optional(),
  moveToReviewPhase: z.enum(REVIEW_PHASES_ORDERED).optional(),
  currentPhaseExtraDocTypes: z.array(z.string()).optional(),
  DO_NOT_RUN_SIMULATION: z.boolean().default(false).optional(),
});

export type TSaveMetaDossier = z.infer<typeof ZSaveMetaDossier>;

export const ZUpdateAbricoDocumentInfo = z.object({
  dossierId: z.string().min(1),
  newDocumentTypeRowId: z.enum(ABRICO_DOCUMENT_ROW_IDS),
  newAssociatedOperations: z.array(
    z.object({ rowId: z.string().min(1), codeAdeme: z.string().min(1) })
  ),
});

export type TUpdateAbricoDocumentInfo = z.infer<
  typeof ZUpdateAbricoDocumentInfo
>;

export const ZMetaDossierDocument = z.object({
  rowId: z.string().min(1),
  dossierId: z.string().min(1),
  reviewed: z.boolean().default(false),
  isValid: z.boolean().nullable().default(null),
  reviewFeedbackMarkdown: z.string().nullable().default(null),
  reviewedAt: z.string().datetime().nullable().default(null),
  reviewedBy: z.string().nullable().default(null),
  glideDocTypeRowId: z.string().min(1),
  associatedOperations: z.array(
    z.object({ rowId: z.string().min(1), codeAdeme: z.string().min(1) })
  ),
});

export const _MetaDossierBaseForChantier = {
  // That's the chantier rowId
  rowId: z.string().min(1),
  name: z.string().min(1),
  isDemo: z.boolean().default(false),
  dossierPhase: z.enum(REVIEW_PHASES_ORDERED).nullable(),
  documents: z.array(ZMetaDossierDocument).default([]),
  currentPhaseIsValid: z.boolean().nullable().default(null),
  currentPhaseDocumentRowIds: z.array(z.string().min(1)).default([]),
  currentPhaseMissingDocTypes: z.array(z.string().min(1)).default([]),
  currentPhaseExtraDocTypes: z.array(z.string().min(1)).default([]),
  internalReviewNotesMarkdown: z.string().nullable().default(null),
} as const;

export const ZMetaDossierBaseForChantier = z.intersection(
  z.object(_MetaDossierBaseForChantier),
  z.object({
    codesAdeme: z.array(z.string()).default([]),
    artisansSirets: z.array(z.string()).default([]),
  })
);

export type TMetaDossierBaseForChantier = z.infer<
  typeof ZMetaDossierBaseForChantier
>;

export type TAttestation = {
  id: number;
  siret: string;
  domaine: string;
  url_qualification: string;
  content_type: string | null;
  gcs_uri: string | null;
  gcs_bucket: string;
};

export type TAttestationResponse = {
  found: boolean;
  company_name: string | null;
  matched: TAttestation[];
  others: TAttestation[];
};
