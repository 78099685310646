import { isEqual } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { reloadMetaDossier } from 'cloudFunctions/functions.ts';
import { queryClient } from 'queries';
import { useAppState } from 'stores/appStore.ts';
import { TMetaDossierBaseForChantier } from 'types/index';
import { labelByGlideDocTypesRowId } from 'utils/constants.ts';
import { getReviewStatus } from 'utils/review/helpers.ts';

type TChantierDocs = {
  phaseDocs: {
    rowId: string;
    label: string;
    isValid: boolean | null;
    reviewStatus: 'pending' | 'valid' | 'invalid';
    dossierId: string;
    url: string;
    glideDocTypeRowId: string;
    reviewFeedbackMarkdown: string;
  }[];
  otherPhaseDocs: {
    rowId: string;
    label: string;
    dossierId: string;
    reviewStatus: 'pending' | 'valid' | 'invalid';
    isValid: boolean | null;
    url: string;
    glideDocTypeRowId: string;
    reviewFeedbackMarkdown: string;
  }[];
};

interface ChantierStateValues {
  metaDossierForChantier: TMetaDossierBaseForChantier | null;
  metaDossierForChantierIsLoading: boolean | null;
  computedChantierDocs: TChantierDocs;
  chantierInternalReviewNotesMarkdown: string | null;
  remoteChantierInternalReviewNotesMarkdown: string | null;
  remoteCurrentPhaseIsValid: boolean | null;
  currentPhaseIsValid: boolean | null;
  remoteCurrentPhaseMissingDocTypes: string[] | null;
  remoteCurrentPhaseExtraDocTypes: string[] | null;
  currentPhaseExtraDocTypes: string[] | null;
  remoteMarkdownFeedbackByDocRowId: Record<string, string | null> | null;
  markdownFeedbackByDocRowId: Record<string, string | null> | null;
}

interface ChantierState extends ChantierStateValues {
  setMetaDossierForChantier: (
    metaDossierForChantier: TMetaDossierBaseForChantier
  ) => void;
  setMetaDossierForChantierIsLoading: (isLoading: boolean) => void;
  setChantierInternalReviewNotesMarkdown: (markdown: string | null) => void;
  setCurrentPhaseExtraDocTypes: (extraDocTypes: string[]) => void;
  setMarkdownFeedbackReview: (rowId: string, markdown: string | null) => void;
  resetState: () => void;
}

const getDefaultStateValues = (): ChantierStateValues => ({
  metaDossierForChantier: null as TMetaDossierBaseForChantier | null,
  metaDossierForChantierIsLoading: false as boolean,
  computedChantierDocs: { phaseDocs: [], otherPhaseDocs: [] },
  chantierInternalReviewNotesMarkdown: null as string | null,
  remoteChantierInternalReviewNotesMarkdown: null as string | null,
  remoteCurrentPhaseIsValid: null as boolean | null,
  remoteCurrentPhaseMissingDocTypes: [] as string[],
  remoteCurrentPhaseExtraDocTypes: [] as string[],
  currentPhaseExtraDocTypes: null as string[] | null,
  currentPhaseIsValid: null as boolean | null,
  remoteMarkdownFeedbackByDocRowId: null as Record<
    string,
    string | null
  > | null,
  markdownFeedbackByDocRowId: null as Record<string, string | null> | null,
});

// TODO move meta elements here
export const useChantierState = create<ChantierState>()(
  devtools(
    (set, get) => ({
      ...getDefaultStateValues(),
      setMetaDossierForChantier: (metaDossierForChantier) => {
        const computedChantierDocs: TChantierDocs = {
          phaseDocs: [],
          otherPhaseDocs: [],
        };

        const docsByRowId = Object.fromEntries(
          metaDossierForChantier.documents.map((doc) => [doc.rowId, doc])
        );
        const urlParams = useAppState.getState().urlParams;

        metaDossierForChantier.currentPhaseDocumentRowIds.forEach((rowId) => {
          if (!docsByRowId[rowId]) {
            console.error("Document doesn't exist in meta dossier", rowId);
            return;
          }
          computedChantierDocs.phaseDocs.push({
            rowId,
            label:
              labelByGlideDocTypesRowId[docsByRowId[rowId].glideDocTypeRowId],
            isValid: docsByRowId[rowId].isValid,
            dossierId: docsByRowId[rowId].dossierId,
            url: `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/doc/${docsByRowId[rowId].dossierId}`,
            glideDocTypeRowId: docsByRowId[rowId].glideDocTypeRowId,
            reviewStatus: getReviewStatus(docsByRowId[rowId]),
            reviewFeedbackMarkdown:
              docsByRowId[rowId].reviewFeedbackMarkdown ?? '',
          });
        });

        const currentPhaseDocumentRowIds = new Set(
          metaDossierForChantier.currentPhaseDocumentRowIds
        );

        metaDossierForChantier.documents.forEach((doc) => {
          if (!currentPhaseDocumentRowIds.has(doc.rowId)) {
            computedChantierDocs.otherPhaseDocs.push({
              rowId: doc.rowId,
              label: labelByGlideDocTypesRowId[doc.glideDocTypeRowId],
              dossierId: doc.dossierId,
              isValid: doc.isValid,
              reviewStatus: getReviewStatus(doc),
              url: `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/doc/${doc.dossierId}`,
              glideDocTypeRowId: doc.glideDocTypeRowId,
              reviewFeedbackMarkdown: doc.reviewFeedbackMarkdown ?? '',
            });
          }
        });

        computedChantierDocs.otherPhaseDocs.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        const remoteMarkdownFeedbackByDocRowId = Object.fromEntries(
          metaDossierForChantier.documents.map((doc) => [
            doc.rowId,
            doc.reviewFeedbackMarkdown ?? '',
          ])
        );
        set({
          metaDossierForChantier,
          computedChantierDocs,
          remoteChantierInternalReviewNotesMarkdown:
            metaDossierForChantier.internalReviewNotesMarkdown ?? '',
          chantierInternalReviewNotesMarkdown:
            get().chantierInternalReviewNotesMarkdown ??
            metaDossierForChantier.internalReviewNotesMarkdown ??
            '',
          remoteCurrentPhaseIsValid: metaDossierForChantier.currentPhaseIsValid,
          remoteCurrentPhaseMissingDocTypes:
            metaDossierForChantier.currentPhaseMissingDocTypes,
          remoteCurrentPhaseExtraDocTypes:
            metaDossierForChantier.currentPhaseExtraDocTypes,
          currentPhaseExtraDocTypes:
            get().currentPhaseExtraDocTypes ??
            metaDossierForChantier.currentPhaseExtraDocTypes,
          remoteMarkdownFeedbackByDocRowId: remoteMarkdownFeedbackByDocRowId,
          markdownFeedbackByDocRowId:
            get().markdownFeedbackByDocRowId ??
            remoteMarkdownFeedbackByDocRowId,
        });
      },
      setMetaDossierForChantierIsLoading: (isLoading) => {
        set({ metaDossierForChantierIsLoading: isLoading });
      },
      setChantierInternalReviewNotesMarkdown: (markdown: string | null) => {
        set(() => ({ chantierInternalReviewNotesMarkdown: markdown }));
      },
      setCurrentPhaseExtraDocTypes: (extraDocTypes) => {
        set(() => ({ currentPhaseExtraDocTypes: extraDocTypes }));
      },
      setMarkdownFeedbackReview(rowId: string, markdown: string | null) {
        set({
          markdownFeedbackByDocRowId: {
            ...get().markdownFeedbackByDocRowId,
            [rowId]: markdown,
          },
        });
      },
      resetState: () => {
        set(getDefaultStateValues());
        queryClient.invalidateQueries({
          queryKey: ['chantier', 'meta'],
        });
      },
    }),
    {
      name: 'chantier-store',
    }
  )
);

useChantierState.subscribe((state) => {
  const hasExtrasDiffWithRemote = !isEqual(
    new Set(state.remoteCurrentPhaseExtraDocTypes ?? []),
    new Set(state.currentPhaseExtraDocTypes ?? [])
  );
  const hasChanges =
    state.remoteChantierInternalReviewNotesMarkdown !==
      state.chantierInternalReviewNotesMarkdown ||
    hasExtrasDiffWithRemote ||
    !isEqual(
      state.markdownFeedbackByDocRowId,
      state.remoteMarkdownFeedbackByDocRowId
    );
  if (
    hasChanges !== useAppState.getState().hasCRMChantierRelatedChangesToSave
  ) {
    useAppState.getState().setHasCRMChantierRelatedChangesToSave(hasChanges);
  }

  const currentPhaseIsValid =
    (state.remoteCurrentPhaseMissingDocTypes ?? []).length === 0 &&
    state.computedChantierDocs.phaseDocs.every((doc) => doc.isValid) &&
    !hasExtrasDiffWithRemote;

  if (currentPhaseIsValid !== state.currentPhaseIsValid) {
    useChantierState.setState({ currentPhaseIsValid });
  }
});

useAppState.subscribe(async (state, prevState) => {
  const prevChantierId = prevState.urlParams?.chantierId;
  const newChantierId = state.urlParams?.chantierId;

  const prevDossierId = prevState.urlParams?.dossierId;
  const newDossierId = state.urlParams?.dossierId;
  if (newDossierId !== prevDossierId) {
    useChantierState.getState().resetState();
  }

  if (newChantierId !== prevChantierId) {
    if (newChantierId) {
      useChantierState.getState().setMetaDossierForChantierIsLoading(true);
      reloadMetaDossier({
        mode: 'fromChantierId',
        chantierRowId: newChantierId,
      }).then(() => {
        console.timeEnd('Reloading meta dossier');
        queryClient.invalidateQueries({
          queryKey: ['chantier', 'meta'],
        });
        queryClient.invalidateQueries({
          queryKey: ['dossier', 'meta'],
        });
      });
    }
  }
});
