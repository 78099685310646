import PostAddIcon from '@mui/icons-material/PostAdd';
import SearchIcon from '@mui/icons-material/Search';
import { DialogActions } from '@mui/joy';
import Button from '@mui/joy/Button';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { range } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AttestationsFinder from 'components/AttestationRGE/AttestationsFinder.tsx';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';

export default function AddRgeBtn({
  defaultSirets,
  variant,
  defaultCodesAdeme,
}: {
  defaultSirets: string[];
  defaultCodesAdeme: string[];
  variant: 'add' | 'search';
}) {
  const { t } = useTranslation();

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const [rgeDialogOpen, setRgeDialogOpen] = useState(false);
  const [phase, setPhase] = useState<'select_siret' | 'search_attestation'>(
    defaultSirets.length > 1 ? 'select_siret' : 'search_attestation'
  );
  const [selectedSiret, setSelectedSiret] = useState<string>(
    defaultSirets.length === 1 ? defaultSirets[0] : ''
  );

  const handleAddToDoc = useCallback(
    async (pdfBytes: Uint8Array) => {
      const instance = await useAppState.getState().getInstance();
      const docToInsert = await instance.Core.createDocument(pdfBytes);

      const { documentViewer } = instance.Core;
      const currentDoc = documentViewer.getDocument();
      await currentDoc.insertPages(
        docToInsert,
        range(1, docToInsert.getPageCount() + 1),
        documentViewer.getCurrentPage() + 1
      );
      enqueueSnackbar(t(`attestationRge.modal.added_to_document`), {
        variant: 'success',
      });
    },
    [t]
  );

  useEffect(() => {
    setPhase(defaultSirets.length > 1 ? 'select_siret' : 'search_attestation');
  }, [defaultSirets.length]);

  useEffect(() => {
    if (!rgeDialogOpen) {
      setSelectedSiret(defaultSirets.length === 1 ? defaultSirets[0] : '');
      setPhase(
        defaultSirets.length > 1 ? 'select_siret' : 'search_attestation'
      );
    }
  }, [defaultSirets, rgeDialogOpen]);

  return (
    <>
      <Button
        startDecorator={
          (variant ?? 'add') === 'add' ? <PostAddIcon /> : <SearchIcon />
        }
        size={'sm'}
        variant={'outlined'}
        disabled={isInReadOnlyMode}
        onClick={() => setRgeDialogOpen(true)}
      >
        RGE
      </Button>
      <Modal
        open={rgeDialogOpen}
        onClose={() => {
          setRgeDialogOpen(false);
        }}
        keepMounted={false}
      >
        <ModalDialog
          onKeyDown={(e) => e.stopPropagation()}
          onMouseMove={(e) => e.stopPropagation()}
          sx={{ width: '80vw', maxWidth: '800px' }}
        >
          <DialogTitle>{t('attestationRge.modal.title')}</DialogTitle>
          <DialogContent>
            {phase === 'select_siret' ? (
              <Stack gap={2}>
                <Typography>{t(`attestationRge.modal.description`)}</Typography>
                {[
                  ...defaultSirets,
                  t(`attestationRge.modal.manual_search`),
                ].map((siret) => (
                  <Button
                    variant={'outlined'}
                    key={siret}
                    onClick={() => {
                      if (siret !== t(`attestationRge.modal.manual_search`)) {
                        setSelectedSiret(siret);
                      }
                      setPhase('search_attestation');
                    }}
                  >
                    {siret}
                  </Button>
                ))}
              </Stack>
            ) : (
              <>
                <AttestationsFinder
                  initialSiret={selectedSiret}
                  initialCodesAdeme={defaultCodesAdeme}
                  onClickAddToPDF={
                    variant === 'add' ? handleAddToDoc : undefined
                  }
                />
              </>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
